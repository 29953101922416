import {Button, FloatingLabel, Form, Table} from "react-bootstrap";
import {fetchAPI} from "../API";
import ModalConfirmation from "../ModalConfirmation";
import {flash} from "react-universal-flash";
import {useDispatch} from "react-redux";
import PaymentHandle from "../Stripe/PaymentHandle";
import * as React from 'react';
import {ReactElement} from 'react';
import {Appointment, ErrorAPI, isValidErrorAPI} from "../../types/type";
import ErrorJWT from "../../Errors/ErrorJWT";

let dateRdv: Date,
    horaireRdv,
    rdvTakens: Map<string, string[]>;

/**
 * A component displaying a calendar allowing customers to choose a time for their appointment
 * @param jwt The jwt retrieved when the user logs in
 * @param typeRdv The type of appointment desired by the customer
 * @param durationRdv Appointment duration
 * @param idSite Customer-selected site identifier
 * @param idService
 * @param modifRdv Boolean indicating whether to modify an existing appointment
 * @param rdvModif The object representing the appointment you want to modify if you want to modify a rendering
 * @param durationStringRdv
 * @return {Element}
 * @constructor
 */
export default function Planning(
    {jwt, typeRdv, durationRdv, idSite, idService, modifRdv = false, rdvModif = null, durationStringRdv}:
        {
            jwt: string,
            typeRdv: string,
            durationRdv: number,
            idSite: number,
            idService: number,
            modifRdv?: boolean,
            durationStringRdv?: string
            rdvModif?: Appointment
        }
): React.ReactElement {

    const todayConst = new Date();

    const dispatch = useDispatch();

    const [descriptionModal, setDescriptionModal] = React.useState<string>("");
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [sujetTextArea, setSujetTextArea] = React.useState<string>("");
    const [showModalPayment, setShowModalPayment] = React.useState<boolean>(true);

    const refModalPayment = React.useRef<HTMLElement>();

    //Journée de départ du tableau qui peut changer suivant le parcours de l'utilisateur (n'est pas forcement aujourd'hui)
    const [today, setToday] = React.useState<Date>(new Date());

    const [render, setRender] = React.useState<ReactElement[]>([]);

    const [modalPaiment, setModalPaiment] = React.useState<ReactElement>(<></>);

    /**
     * @description Triggers an event confirming the user's choice of appointment
     */
    function launchEvent() {
        const confirmationRdv = new CustomEvent("onRdvConfirm");
        document.dispatchEvent(confirmationRdv);
    }

    React.useEffect(() => {
        getContentTab().then();
    }, [today]);


    /**
     * @description Asynchronous function returning all the times of a day that are already in use
     * @param day The day for which you want to know which times are already booked
     * @return {Promise<*[]>}
     */
    async function getRdvTakenForWeek(day: string): Promise<Map<string, string[]>> {
        const tabHoraire: Map<string, string[]> = new Map<string, string[]>();

        const rdvs: Appointment[] | ErrorAPI = await fetchAPI.getRdvForWeek(day, jwt);

        if (isValidErrorAPI(rdvs)) throw new ErrorJWT("", rdvs.code);


        if (modifRdv) {
            for (let rdv of rdvs as Appointment[]) {
                if (rdv.idRdv !== rdvModif.idRdv) {
                    setHoraires(rdv, tabHoraire)
                }
            }
        } else {
            for (let rdv of rdvs as Appointment[]) {
                setHoraires(rdv, tabHoraire);
            }
        }

        return tabHoraire;
    }

    function setHoraires(rdv: Appointment, tabHoraire: Map<string, string[]>): void {
        let arrayHoraire: string[] = tabHoraire.get(rdv.date.toDateString());
        let keyIsSet: boolean = true;

        if (!arrayHoraire) {
            arrayHoraire = [];
            keyIsSet = false;
        }

        for (let horaire of rdv.horaires) {
            arrayHoraire.push(horaire);
        }

        if (!keyIsSet) tabHoraire.set(rdv.date.toDateString(), arrayHoraire);
    }

    /**
     * @description Retrieves all schedules already taken for each day of the next 7 days, starting today.
     * @return {Promise<*[]>}
     */
    async function getHoraireRdvTaken(): Promise<Map<string, string[]>> {
        let date = new Date(today.getTime());
        try {
            return await getRdvTakenForWeek(date.toISOString().slice(0, 10));
        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }

    }

    /**
     * @description Rendering the schedule in relation to the times already taken for the next 7 days
     * @return {Promise<void>}
     */
    async function getContentTab(): Promise<void> {

        let render: ReactElement[] = [];

        rdvTakens = await getHoraireRdvTaken();


        const todayHours = todayConst.getHours();
        const todayDay = todayConst.toLocaleDateString();



        for (let i = 0; i < 10; i++) {

            render.push(
                <tr key={i}>
                    {Array.from({length: 7}).map((_, index) => {

                        let date = new Date(today.getTime());
                        date.setDate(date.getDate() + index);

                        const jourSemaine = date.toLocaleDateString('fr', {weekday: "long"});
                        const dateJourSemaine = date.toLocaleDateString();

                        let horairesTaken = rdvTakens.get(date.toDateString());

                        if (!horairesTaken) horairesTaken = [];


                        if (!horairesTaken.includes((8 + i).toString()) && jourSemaine !== 'samedi' && jourSemaine !== 'dimanche') {


                            if (todayHours >= 8+i && todayDay  === dateJourSemaine) {
                                return (
                                    <td key={index}>
                                        <Button variant="secondary" className="boutonTime">{`${8 + i}h`}</Button>
                                    </td>
                                )
                            }

                            return (
                                <td key={index}>
                                    <Button onClick={handleChoixHoraire} data-horaire={8 + i}
                                            data-date={date}
                                            className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            );
                        } else {
                            return (
                                <td key={index}>
                                    <Button style={{visibility: "hidden"}} className="boutonTime">{`${8 + i}h`}</Button>
                                </td>
                            )
                        }
                    })}
                </tr>
            );
        }
        setRender(render);
    }

    /**
     * @description Checks whether the customer's schedule coincides with the constraints of the type of Service chosen
     * @return {boolean} true if the schedules requested by the customer are correct and false otherwise
     */
    function verifHoraireValide(): boolean {
        let duration = Math.ceil(durationRdv);

        const horairesTaken = rdvTakens.get(dateRdv.toDateString());

        if (!horairesTaken) return true;

        for (let i = 1; i < duration; i++) {
            if (horairesTaken.includes((Number(horaireRdv) + i).toString())) return false;
        }

        return true;
    }

    /**
     * Validates the customer's choice of schedule by displaying a confirmation bubble.
     * @param e Event containing all the information about the appointment and times chosen by the customer.
     */
    function handleChoixHoraire(e) {
        dateRdv = new Date(e.target.dataset.date);
        horaireRdv = e.target.dataset.horaire;

        if (verifHoraireValide()) {

            setModalShow(true);

            if (modifRdv) setDescriptionModal(`Souhaitez vous confirmer la modification du rendez-vous pour le ${dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} ${dateRdv.toLocaleDateString()} à ${horaireRdv}h`)
            else setDescriptionModal(`Souhaitez vous confirmer la prise d'un rendez vous pour le ${dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} ${dateRdv.toLocaleDateString()} à ${horaireRdv}h`);
        } else {
            flash(5000, "danger", "Le créneau que vous souhaitez n'est pas assez grand.");
        }
    }

    /**
     * @description Function to carry out processing when the desired appointment is validated by the customer
     */
    function handleConfirmModal() {
        const dateFormated = dateRdv.toISOString().slice(0, 10);

        let horaires = [];

        horaires.push(horaireRdv.toString());

        for (let i = 1; i < Math.ceil(durationRdv); i++) {
            horaires.push((Number(horaireRdv) + i).toString());
        }

        if (modifRdv) {
            fetchAPI.updateRdv(rdvModif.idRdv, null, dateFormated, horaires, jwt)
                .then(response => {
                    console.log(response);
                    setModalShow(false);
                    flash(5000, "success", "Le rendez-vous à bien été modifié !");
                    launchEvent();
                });
        } else {
            if (sujetTextArea.length !== 0) {
                setModalShow(false);
                setShowModalPayment(true);
                console.log(showModalPayment)
                if (!refModalPayment.current) setModalPaiment(<PaymentHandle refP={refModalPayment} showModal={showModalPayment} paymentComplete={() => handlePaymentComplete(dateFormated, horaires)} paymentClose={() => setShowModalPayment(false)}></PaymentHandle>);
            }
        }

    }

    /**
     * @description Function recording the customer's appointment when validating payment for the requested Service
     * @param date The date of the requested appointment
     * @param horaires Appointment times
     */
    function handlePaymentComplete(date, horaires: string[]): void {
        fetchAPI.takeRdv(date, horaires, sujetTextArea, typeRdv, idSite.toString(), idService.toString(), jwt)
            .then(response => {
                console.log(response);
                setModalShow(false);
                flash(5000, "success", "Le rendez-vous à bien été confirmer !");
                launchEvent();
            });
    }



    /**
     * Function to obtain the body of the appointment confirmation bubble
     * @return {Element} An element representing the modal body
     */
    function getBodyModal(): React.ReactElement {
        if (modifRdv) {
            return (
                <>
                    {descriptionModal}
                </>
            )
        } else {
            return (
                <>
                    <FloatingLabel
                        controlId="floatingTextarea"
                        label="Sujet"
                        className="mb-3"
                    >
                        <Form.Control onChange={(e) => setSujetTextArea(e.target.value)} value={sujetTextArea}
                                      as="textarea"
                                      placeholder="Ecrivez quelques indications ici" style={{resize: "none"}}/>
                    </FloatingLabel>
                    {descriptionModal}
                </>
            )
        }

    }

    /**
     * @description Function to retrieve appointment duration in hours and minutes
     * @return {string} A string representing the appointment time in hours and minutes
     */
    function getDuration() {
        if (durationRdv > 1 || durationRdv < 1) {
            let splitedDuration = [Math.floor(durationRdv), durationRdv - Math.floor(durationRdv)]
            if (durationRdv > 1) return splitedDuration[0] + "h" + splitedDuration[1]*60;
            else return splitedDuration[1] + "m"
        } else return durationRdv + "h";
    }

    /**
     * @description Function to advance the schedule by one week
     */
    function nextWeek(): void {
        let tomorow: Date = new Date(today.getTime());
        tomorow.setDate(today.getDate()+7);
        setToday(tomorow);
    }

    /**
     * @description Function to move the schedule back one week
     */
    function previousWeek(): void {
        let yesterday: Date = new Date(today.getTime());
        yesterday.setDate(today.getDate()-7);

        if (yesterday.getFullYear() >= todayConst.getFullYear()) {
            if (yesterday.getMonth() === todayConst.getMonth()) {
                if (yesterday.getDate() >= todayConst.getDate()) setToday(yesterday);
            } else if (yesterday.getMonth() > todayConst.getMonth()) setToday(yesterday);
        }
    }


    return (
        <>
            <div>
                {modifRdv ?
                    <h3 className="tabTimeTitle">Modification du rendez-vous de {rdvModif.horaires[0]}h
                        du {rdvModif.date.toLocaleDateString("fr", {weekday: "long"})} {rdvModif.date.toLocaleDateString()} (durée {durationStringRdv})</h3>
                    :
                    <h3 className="tabTimeTitle">Choix de la date et de l'horaire pour {typeRdv} d'une durée
                        de {getDuration()}</h3>

                }
                <div className="row">
                    <div className="col">
                        <Button onClick={previousWeek} style={{float: "left"}} className="primary">{"<"}</Button>
                    </div>
                    <div className="col">
                        <Button onClick={nextWeek} style={{float: "right"}} className="primary">{">"}</Button>
                    </div>
                </div>

                <Table responsive>
                    <thead>
                    <tr>
                        {Array.from({length: 7}).map((_, index) => {
                            let date = new Date(today.getTime());
                            date.setDate(date.getDate() + index);
                            return (
                                <th key={index}>
                                    <div>
                                        {date.toLocaleDateString('fr-Fr', {weekday: 'long'})}
                                    </div>
                                    <span className="dateSpan">
                                                        {date.toLocaleDateString()}
                                                    </span>
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {render}
                    </tbody>
                </Table>
            </div>

            <ModalConfirmation titre="Confirmation de rendez vous" body={getBodyModal()}
                               fonctionBtnAccept={handleConfirmModal} fonctionBtnRefuse={() => setModalShow(false)}
                               show={modalShow}></ModalConfirmation>


            {modalPaiment}

        </>
    );
}
