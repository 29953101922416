import {InterventionSite} from "../../types/type";
import * as React from 'react';
import ListGroup from "react-bootstrap/ListGroup";
import {deleteAllFlashes, flash} from "react-universal-flash";
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import {Button} from "react-bootstrap";


const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

function ChangeView({center, zoom}): null {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

function ButtonControl({ position }) {
    const map = useMap();

    // Memoize the button so he don't rerender with position change of the map
    const button = React.useMemo(
        () => (
            <Button onClick={() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    map.setView([position.coords.latitude, position.coords.longitude], 13)
                }, (e:any) => console.log(e), {enableHighAccuracy: true})

            }}>Ma position</Button>
        ),
        [],
    )

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright

    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar">{button}</div>
        </div>
    );
}


export default function InterventionSiteChoice(
    {sites}: {
        sites: InterventionSite[]
    }
): React.ReactElement {

    const [coordinates, setCoordinates] = React.useState<[number, number]>([0, 0]);

    function launchEvent(idSite: number | undefined): void {
        const event: CustomEvent<number | undefined> = new CustomEvent("onSiteChoice", {detail: idSite});
        document.dispatchEvent(event);
    }

    React.useEffect((): void => {
        if (sites.length === 0) {
            deleteAllFlashes();
            flash(5000, "warning", "Aucun site d'intervention trouvé.");
        }
    }, []);


    function choiceSite(idSite: number, coordinates: [number, number]) {
        setCoordinates(coordinates);

        const itemsList: NodeListOf<Element> = document.querySelectorAll(".siteIntervention");

        for (const item of itemsList) {
            item.classList.remove("active");
        }

        const itemActive: Element = document.querySelector(`[data-id="${idSite.toString()}"]`);

        itemActive.classList.add("active");
    }

    return (
        <div className="siteChoiceContainer">
            <section className="interventionSiteSection">
                <h2 style={{textAlign: "center"}}>Choix du lieu d'intervention</h2>
                <ListGroup>
                    {sites.map((site) => (
                        <ListGroup.Item data-id={site.id.toString()} key={site.id} action onClick={(e) => {
                            choiceSite(site.id, [site.latitude, site.longitude]);
                        }} className="listeChoixRdv siteIntervention">
                            <div className="enfant">
                                <p className="titleService">{site.nomSiteIntervention}</p>
                                <p className="descService">{site.locationSiteIntervention}</p>
                            </div>

                            <div className="badgeGrid">
                                <div className="btn btn-dark" style={{marginTop:"50%"}} onClick={() => launchEvent(site.id)}>Choisir</div>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </section>

            <aside className="asideMapContainer">
                <MapContainer center={[46.71109, 1.7191036]} zoom={15} scrollWheelZoom={true}>
                    <ChangeView center={coordinates} zoom={15}></ChangeView>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ButtonControl position="topright"></ButtonControl>

                    {sites.map((site) => (
                        <Marker eventHandlers={{
                            click: (e: any) => {
                                choiceSite(site.id, [site.latitude, site.longitude]);
                            }
                        }} key={site.id} position={[site.latitude, site.longitude]}>
                            <Popup>
                                {site.nomSiteIntervention}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>

            </aside>

        </div>
    )
}

/*
<Row style={{justifyContent: "start"}}>
    <Col sm={6}>
        <h2>Choix du lieu d'intervention</h2>
        <ListGroup>
            {sites.map((site) => (
                <ListGroup.Item data-id={site.id.toString()} key={site.id} action onClick={(e) => {
                    choiceSite(site.id, [site.latitude, site.longitude]);
                }} className="listeChoixRdv siteIntervention">
                    <div className="enfant">
                        Nom : {site.nomSiteIntervention}
                    </div>
                    <div className="enfant">
                        {site.locationSiteIntervention} : Lieu
                    </div>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </Col>

    <Col sm={6}>
        <MapContainer center={[46.71109, 1.7191036]} zoom={15} scrollWheelZoom={true}>
            <ChangeView center={coordinates} zoom={15}></ChangeView>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ButtonControl position="topright"></ButtonControl>

            {sites.map((site) => (
                <Marker eventHandlers={{
                    click: (e: any) => {
                        choiceSite(site.id, [site.latitude, site.longitude]);
                    }
                }} key={site.id} position={[site.latitude, site.longitude]}>
                    <Popup>
                        {site.nomSiteIntervention}
                    </Popup>
                </Marker>
            ))}
        </MapContainer>

        <div className="buttonsLeafletContainer">
            <Button style={{marginRight: "5%"}}>Confirmer le lieu</Button>
            <Button onClick={() => launchEvent(undefined, [0, 0])} style={{marginLeft: "5%"}}
                    variant={"danger"}>Retour</Button>
        </div>

    </Col>

</Row>

 */
