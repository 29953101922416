import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    ChatContainer,
    ConversationHeader,
    MainContainer,
    Message,
    MessageInput,
    MessageList,
    MessageSeparator,
    Status, UserStatus,
    VideoCallButton,
} from "@chatscope/chat-ui-kit-react";
import * as React from 'react';
import {fetchAPI} from "./API";
import {socket} from "../socket";
import CardRdv from "./CardRdv";
import SignatureIntervention from "./SignatureIntervention";
import {Badge} from "react-bootstrap";
import VisioConferenceTest from "./Visio/VisioConferenceTest";
import {flash} from "react-universal-flash";
import VisioConferenceMultipeer from "./Visio/VisioConferenceMultipeer";
import {RdvDetailProps} from "../types/propsType";
import {ChatMessage} from '../types/type'
import {patternImage} from "../types/regexPattern";

export default function RdvDetail(
    {rdv, jwt, idUser, isEmploye}: RdvDetailProps
): React.ReactElement {


    const [messages, setMessages] = React.useState([]);

    const [status, setStatus] = React.useState<UserStatus>("dnd");

    const refInput = React.useRef<HTMLInputElement>();

    const [isSigning, setIsSigning] = React.useState<boolean>(false);

    const [isVisio, setIsVisio] = React.useState<boolean>(false);

    const [sid, setSid] = React.useState<string>("");


    React.useEffect(() => {
        refreshVisual(rdv.messages);

        socket.connect();

        if (socket.connected) setStatus("available");

        function onConnect() {
            setStatus("available");
            socket.emit("joinRoom", rdv.idRdv);
        }

        function onReady(msg: any) {
            setSid(msg.sid);
        }

        function onDisconnect() {
            setStatus("dnd");
        }

        function onChat(msg: any) {
            if (msg.idRdv === rdv.idRdv) refreshMessages().then();
        }

        function onInterventionValidated() {
            setIsSigning(true);
        }

        function onSigEnregistred() {
            setIsSigning(false);
        }


        document.addEventListener("onInterventionValidated", onInterventionValidated);
        document.addEventListener("onSigEnregistred", onSigEnregistred);
        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on("chat", onChat);
        socket.on("ready", onReady);


        return () => {
            socket.disconnect();
            document.removeEventListener("onInterventionValidated", onInterventionValidated);
            document.removeEventListener("onSigEnregistred", onSigEnregistred);
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('chat', onChat);
            socket.off("ready", onReady);
        }

    }, []);


    async function refreshMessages(): Promise<void> {
        const messages: ChatMessage[] = await fetchAPI.getAllMessagesByRdv(rdv.idRdv, jwt);
        console.log(messages)
        refreshVisual(messages);
    }

    function refreshVisual(messages: ChatMessage[]) {
        let tabMessage = [];
        let lastDate = null;

        messages.sort(function (a, b) {

            /*
            let [dayA, monthA, yearA] = a.date.split("/");
            let dateA = new Date(`${yearA}-${monthA}-${dayA}`);

            let [dayB, monthB, yearB] = b.date.split("/");
            let dateB = new Date(`${yearB}-${monthB}-${dayB}`);

             */
            return a.date.getTime() - b.date.getTime();
        });

        for (let message of messages) {
            /*
            let [day, month, year] = message.date.split("/");
            let date = new Date(`${year}-${month}-${day}`);

             */

            const date: Date = message.date;


            let timeMessage,
                direction,
                messageSeparator: React.ReactElement | string = "";

            if (lastDate === null) lastDate = date;
            else if (lastDate.toLocaleDateString() !== date.toLocaleDateString()) {
                lastDate = date;
                messageSeparator = <MessageSeparator
                    content={date.toLocaleDateString('fr-Fr', {weekday: 'long'}) + " " + date.getDate() + " " + date.toLocaleDateString("fr", {month: "long"}) + " " + date.getFullYear()}/>;
            }

            if (date.toLocaleDateString() === new Date().toLocaleDateString()) timeMessage = "Aujourd'hui" + " " + message.horaire;
            else timeMessage = message.date.toLocaleDateString() + " " + message.horaire;


            if (message.client.id === idUser) direction = "outgoing";
            else direction = "incoming";

            if (messageSeparator !== "") tabMessage.push(messageSeparator);

            let badge: React.ReactElement = <Badge bg="secondary">Client</Badge>;
            if (message.client.roles.includes("ROLE_CLIENTPRO")) badge = <Badge bg="warning">Pro</Badge>
            else if (message.client.roles.includes("ROLE_EMPLOYE")) badge = <Badge bg="primary">Employe</Badge>


            if (message.imagePath) {
                const contentUrl: string = message.imagePath;
                if (new RegExp(patternImage).test(contentUrl)) {
                    tabMessage.push(
                        <Message
                            key={message.id}
                            model={{
                                sentTime: timeMessage,
                                sender: message.client.nom,
                                direction: direction,
                                position: "single"
                            }}
                        >
                            <Message.ImageContent src={contentUrl} width={200}/>
                            <Message.Header style={{justifyContent: "center"}}>
                                {badge}
                            </Message.Header>
                            <Message.Footer sender={message.client.nom} sentTime={timeMessage}/>
                        </Message>
                    );
                } else {
                    tabMessage.push(
                        <Message
                            key={message.id}
                            model={{
                                sentTime: timeMessage,
                                sender: message.client.nom,
                                direction: direction,
                                position: "single"
                            }}
                        >
                            <Message.CustomContent>
                                <a href={contentUrl}>{message.message}</a>
                            </Message.CustomContent>
                            <Message.Header style={{justifyContent: "center"}}>
                                {badge}
                            </Message.Header>
                            <Message.Footer sender={message.client.nom} sentTime={timeMessage}/>
                        </Message>
                    );
                }

            } else {
                tabMessage.push(
                    <Message
                        key={message.id}
                        model={{
                            message: message.message,
                            sentTime: timeMessage,
                            sender: message.client.nom,
                            direction: direction,
                            position: "single"
                        }}
                    >
                        <Message.Header style={{justifyContent: "center"}}>
                            {badge}
                        </Message.Header>
                        <Message.Footer sender={message.client.nom} sentTime={timeMessage}/>
                    </Message>
                );
            }
        }
        setMessages(tabMessage);
    }

    function sendMessageAPI(message, file = null) {
        let date = new Date();
        fetchAPI.createMessage(message, rdv.idRdv.toString(), date.toISOString().slice(0, 10), `${date.getHours()}:${date.getMinutes()}`, jwt, file)
            .then(response => {
                socket.emit("chat", {
                    idRdv: rdv.idRdv,
                    message: message
                });
                refreshMessages().then();
            });
    }

    function attachFile() {
        refInput.current.click();
    }

    function sendMessageFileAPI(event: any) {
        let file = event.target.files[0];
        sendMessageAPI(file.name.substring(0, file.name.length - 4), file);
    }

    function sendMessageAPIWithoutFile(message: string) {
        sendMessageAPI(message);
    }



    return (
        <>
            {!isSigning ?
                isVisio ?
                    <>
                        <VisioConferenceMultipeer isEmploye={isEmploye} idRdv={rdv.idRdv}></VisioConferenceMultipeer>
                    </>
                    :
                    <>
                        <CardRdv rdv={rdv} isEmploye={isEmploye}></CardRdv>
                        <div style={{height: "500px"}}>
                            <MainContainer>
                                <ChatContainer>
                                    <ConversationHeader>
                                        <ConversationHeader.Actions>
                                            <VideoCallButton onClick={() => setIsVisio(true)}/>
                                        </ConversationHeader.Actions>
                                        <ConversationHeader.Content>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <div style={{alignSelf: "flex-center"}}>
                                                    {rdv.client.prenom + " " + rdv.client.nom}
                                                </div>
                                                <div style={{alignSelf: "flex-center"}}>
                                                    <Status status={status} size="lg"/>
                                                </div>
                                            </div>
                                        </ConversationHeader.Content>
                                    </ConversationHeader>
                                    <MessageList>
                                        {messages}
                                    </MessageList>
                                    <MessageInput onAttachClick={attachFile} onSend={sendMessageAPIWithoutFile}
                                                  placeholder="Ecriver un message juste ici"/>
                                </ChatContainer>
                            </MainContainer>
                        </div>

                        <input onChange={sendMessageFileAPI} type="file" ref={refInput} style={{display: "none"}}/>
                    </>
                :
                <SignatureIntervention rdv={rdv} jwt={jwt}></SignatureIntervention>

            }

        </>
    )
}
