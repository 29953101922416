import React, {useEffect, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useSelector} from 'react-redux';
//Elements graphiques
import OnlineDetect from "./OnlineDetection/OnlineDetect";
import ButtonInstallApp from "./Components/ButtonInstallApp";
import {MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import qrCode from './img/qrCode.png';
import LoginForm from "./Components/LoginForm";
import {useMediaQuery} from "react-responsive";
import {PopupIOS} from "./Components/PopupIOS";
import {jwtDecode} from "jwt-decode";

function App() {


    useEffect(() => {

    }, []);

    return (
        <>
            <PopupIOS></PopupIOS>
            <div>

            </div>
            <OnlineDetect></OnlineDetect>

        </>
  );
}

export default App;
