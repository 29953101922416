import * as React from 'react';
import {fetchAPI} from "./API";
import {Button, Table} from "react-bootstrap";
import ButtonEmploye from "./ButtonEmploye";
import {TableUserProps} from "../types/propsType";
import {User} from "../types/type";

//TODO Utiliser Context de React pour passer les employés et les clients directement sans
// devoir appeler l'API à chaque changement (baisse de performances)
export default function TableUser (
    {jwt, idAdmin, buttons, wantEmploye}: TableUserProps
): React.ReactElement {

    const [render, setRender] = React.useState<React.ReactElement[]>([]);


    React.useEffect(() => {
        getAllUsers().then();
    }, [wantEmploye]);


    async function getAllUsers(): Promise<void> {

        const users: User[] = wantEmploye ? await getEmployes() : await getClients();

        let tabEmployesRender: React.ReactElement[] = [];

        for (const user of users) {

            const buttonsRender: React.ReactElement[] = getRenderOfButtons(user);


            tabEmployesRender.push(createRowForUser(user, buttonsRender));
        }

        setRender(tabEmployesRender);
    }

    function createRowForUser(user: User, buttons: React.ReactElement[]): React.ReactElement {
        return (
            <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                {!wantEmploye && <td>{user.roles.includes("ROLE_CLIENTPRO").toString().toUpperCase()}</td>}
                <td>
                    {buttons}
                </td>
            </tr>
        )
    }

    function getRenderOfButtons(user: User): React.ReactElement[] {
        const buttonsRender: React.ReactElement[] = [];

        for (let button of buttons) {
            buttonsRender.push(<ButtonEmploye variant={button.variant} key={button.eventName} buttonName={button.name} eventName={button.eventName} dataOnClick={user.id}></ButtonEmploye>);
        }

        return buttonsRender
    }

    async function getEmployes(): Promise<User[]> {
        return await fetchAPI.getAllEmployes(jwt, idAdmin);
    }

    async function getClients(): Promise<User[]> {
        return await fetchAPI.getAllClient(jwt, idAdmin);
    }

    return (
        <Table responsive={true}>
            <thead className="table-dark">
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Prenom</th>
                {!wantEmploye && <th>Client pro</th>}
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{verticalAlign: "middle"}}>
            {render}
            </tbody>
        </Table>
    )
}
