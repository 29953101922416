import * as React from "react";
import {fetchAPI} from "./API";
import {Table} from "react-bootstrap";
import ButtonEmploye from "./ButtonEmploye";
import {TableSiteInterventionProps} from "../types/propsType";
import {InterventionSite} from "../types/type";
import {Browser} from "leaflet";
import retina = Browser.retina;

export default function TableSiteIntervention(
    {sites}: TableSiteInterventionProps
): React.ReactElement {

    function getButtonsElements(site: InterventionSite): React.ReactElement[] {
        const tabButtons: React.ReactElement[] = [];

        tabButtons.push(<ButtonEmploye variant="warning" key="onChangeSite" buttonName="Modifier"
                                                      eventName="onChangeSite" dataOnClick={site}></ButtonEmploye>);
        tabButtons.push(<ButtonEmploye variant="danger" key="onSupprSite" buttonName="Supprimer"
                                                      eventName="onSupprSite" dataOnClick={site.id}></ButtonEmploye>);

        return tabButtons;
    }


    function createRowForInterventionSite(site: InterventionSite, buttons: React.ReactElement[]): React.ReactElement {
        return (
            <tr key={site.id}>
                <td>{site.id}</td>
                <td>{site.nomSiteIntervention}</td>
                <td>{site.locationSiteIntervention}</td>
                <td>
                    {buttons}
                </td>
            </tr>
        )
    }



    return (
        <Table responsive={true}>
            <thead className="table-dark">
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Adresse</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{verticalAlign: "middle"}}>
            {sites.map(site => {
                const buttons: React.ReactElement[] = getButtonsElements(site);
                return createRowForInterventionSite(site, buttons);
            })}
            </tbody>
        </Table>
    )
}
