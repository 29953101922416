import SignaturePad from "react-signature-pad-wrapper";
import {Button} from "react-bootstrap";
import * as React from 'react';
import {fetchAPI} from "./API";
import {flash} from "react-universal-flash";
import {Appointment} from "../types/type";

export default function SignatureIntervention ({rdv, jwt}: {rdv: Appointment, jwt: string}): React.ReactElement {

    const refSigPad = React.useRef<SignaturePad>();

    function clearSigPad(): void {
        refSigPad.current.clear();
    }

    function launchEvent(): void {
        let event = new CustomEvent("onSigEnregistred");
        document.dispatchEvent(event);
    }

    function validateSig() {
        let signature:SignaturePad = refSigPad.current;
        let canvas: HTMLCanvasElement = signature.canvas.current;
        if (!signature.isEmpty()) {

            canvas.toBlob((blob: Blob): void => {
                let date: Date = new Date();
                let dateToday: string = date.toISOString().slice(0,10);
                let time: string = `${date.getHours()}:${date.getMinutes()}`;
                let nomSignature: string = `sig_${rdv.client.nom}_${rdv.idRdv}_${dateToday}`;
                let file: File = new File([blob], `${nomSignature}.png`, {type: "image/png"})

                navigator.geolocation.getCurrentPosition((geo: GeolocationPosition) => {
                    fetchAPI.makeSignature(nomSignature, rdv.idRdv.toString(), dateToday, time, file,geo.coords.latitude,geo.coords.longitude, jwt)
                        .then(response => {
                            console.log(response);
                            flash(5000, "success", "La signature à été enregistrée.");
                            launchEvent();
                        });
                }, () => {}, {enableHighAccuracy:true});

            }, 'image/png');
        }
    }

    return (
        <>
            <div className="conteneurSignature">
                <h2 style={{position:"fixed"}}>Signature</h2>
                <SignaturePad ref={refSigPad}></SignaturePad>

            </div>
            <div className="conteneurBoutonSignature">
                <Button onClick={clearSigPad} className="mx-5" variant="danger">Effacer</Button>
                <Button onClick={validateSig} className="mx-5" variant="primary">Valider intervention</Button>
            </div>
        </>
    );
}
