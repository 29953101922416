import {createHashRouter, Outlet, RouterProvider, useRouteError} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import App from "../App";
import RDVListeEmploye from "../Pages/RDVListeEmploye";
import GestionUser from "../Pages/GestionUser";
import HistoriqueAdminRdv from "../Pages/HistoriqueAdminRdv";
import GestionSiteIntervention from "../Pages/GestionSiteIntervention";
import GestionClient from "../Pages/GestionClient";
import RDV from "../Pages/RDV";
import PageCompte from "../Pages/PageCompte";
import {
    loaderAllInterventionSiteByIdClientPro,
    loaderAllRdvs,
    loaderAllServices,
    loaderAllUsers,
    loaderAnotherUserRdvs,
    loaderInterventionSiteCreate,
    loaderInterventionSiteUpdate,
    loaderServices,
    loaderUpdateAppointment,
    loaderUserByIdUpdate,
    loaderUserRdvs
} from "./loader";
import {useSelector} from "react-redux";
import {fetchAPI} from "../Components/API";
import GestionClientSpe from "../Pages/GestionClientSpe";
import NavigationSideBar from "../Components/NavBar/NavigationSideBar";
import Login from "../Pages/Login";
import createEmploye from "./formHandler/User/createEmploye";
import MessageFlash from "../Components/FlashMessage/MessageFlash";
import {Flasher} from "react-universal-flash";
import CreationUser from "../Pages/CreationUser";
import ModifUser from "../Pages/ModifUser";
import modifUser from "./formHandler/User/modifUser";
import GestionInterventionSite from "../Pages/GestionInterventionSite";
import actionsInterventionSite from "./formHandler/InterventionSite/actionsInterventionSite";
import DetailRdvModif from "../Components/DetailRdvModif";
import GestionService from "../Pages/GestionService";
import actionsService from "./formHandler/Service/actionsService";
import ErrorPage from "./ErrorPage";
import {Auth} from "../types/type";
import actionsUser from "./formHandler/User/actionsUser";
import * as React from 'react';
import Logout from "./Logout/Logout";


const RoutesCustom = () => {

    // @ts-ignore
    const authObject: Auth = useSelector((state) => state.auth);

    fetchAPI.authObject = authObject;

    const secureRoutes = [
        {
            element: (
                <>
                    <Flasher position="top_center">
                        <MessageFlash/>
                    </Flasher>
                    <NavigationSideBar isEmploye={authObject.isEmploye} isClientPro={authObject.isClientPro}
                                       isAdmin={authObject.isAdmin} jwt={authObject.jwt}
                                       username={authObject.username}/>
                    <div className="conteneur">
                        <Outlet/>
                    </div>
                </>
            ),
            errorElement: <CustomErrorPage/>,
            children: [
                {
                    path: "/",
                    element: <ProtectedRoute authObject={authObject}/>,
                    children: [
                        {
                            path: '/',
                            element: <App/>
                        },
                        {
                            path: '/logout',
                            element: <Logout/>
                        },
                        {
                            path: "/rdvListe",
                            element: <RDVListeEmploye/>
                        },
                        {
                            path: "/gestionUser",
                            element: <GestionUser/>,
                            loader: loaderAllUsers,
                            action: actionsUser
                        },
                        {
                            path: "/gestionService",
                            element: <GestionService/>,
                            loader: loaderAllServices,
                            action: actionsService
                        },
                        {
                            path: "/gestionRdv/:idRdv",
                            element: <DetailRdvModif/>,
                            loader: loaderUpdateAppointment
                        },
                        {
                            path: "/historique",
                            element: <HistoriqueAdminRdv/>,
                            loader: loaderAllRdvs
                        },
                        {
                            path: '/siteIntervention',
                            element: <GestionSiteIntervention/>,
                            loader: loaderAllInterventionSiteByIdClientPro
                        },
                        {
                            path: "/gestionClient",
                            element: <GestionClient/>
                        },
                        {
                            path: "/gestionClient/:idClient",
                            element: <GestionClientSpe/>,
                            loader: loaderAnotherUserRdvs
                        },
                        {
                            path: "/rdv",
                            element: <RDV/>,
                            loader: loaderServices
                        },
                        {
                            path: "/account",
                            element: <PageCompte/>,
                            loader: loaderUserRdvs
                        },
                        {
                            path: "/creationUser/:typeUser",
                            element: <CreationUser/>,
                            action: createEmploye
                        },
                        {
                            path: "/modifUser/:typeUser/:idUser",
                            element: <ModifUser/>,
                            action: modifUser,
                            loader: loaderUserByIdUpdate
                        },
                        {
                            path: "/creationInterventionSite",
                            element: <GestionInterventionSite/>,
                            action: actionsInterventionSite,
                            loader: loaderInterventionSiteCreate
                        },
                        {
                            path: "/updateInterventionSite/:idSite",
                            element: <GestionInterventionSite/>,
                            action: actionsInterventionSite,
                            loader: loaderInterventionSiteUpdate
                        }
                    ]
                }
            ]
        }
    ]

    const noSecureRoutes = [
        {
            path: "/login",
            element:
                <>
                    <Flasher position="top_center">
                        <MessageFlash/>
                    </Flasher>
                    <Login/>
                </>
        }
    ]

    const router = createHashRouter([...secureRoutes, ...noSecureRoutes])


    return <RouterProvider router={router}/>;
}

function CustomErrorPage(): React.ReactElement {
    const error = useRouteError() as any;

    let renderDefault: React.ReactElement;

    console.error(error);

    switch (error.status) {
        case 404:
            renderDefault = <ErrorPage titleError=" Page not found." bodyError="La page demandée n'existe pas"
                                       codeError={404}></ErrorPage>
            break;
        default:
            renderDefault = <ErrorPage titleError=" Error"
                                       bodyError="Une erreur inconnue est survenue, si le problème persiste veuillez contacter l'assistance."
                                       codeError={error.status}></ErrorPage>;
            break;
    }

    return renderDefault;
}


export default RoutesCustom;
