import {Provider} from "react-redux";
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import * as React from 'react';
import {PersistGate} from "redux-persist/integration/react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {Button, Card} from "react-bootstrap";
import TableSiteIntervention from "../Components/TableSiteIntervention";
import {fetchAPI} from "../Components/API";
import {flash} from "react-universal-flash";
import ModalConfirmation from "../Components/ModalConfirmation";
import {NavigateFunction} from "react-router-dom";
import {Auth, InterventionSite, Service} from "../types/type";

export default function GestionSiteIntervention(): React.ReactElement {

    const [showModal, setShowModal] = React.useState<boolean>(false);

    const [idSite, setIdSite] = React.useState<number>(null);

    const navigate: NavigateFunction = useNavigate();

    const [siteInterventions, setSiteInterventions] = React.useState<InterventionSite[]>(useLoaderData() as InterventionSite[]);

    const authObject: Auth = fetchAPI.authObject;

    React.useEffect(() => {

        function onChangeSite(event): void {
            navigate(`/updateInterventionSite/${event.detail.id}`)
        }

        function onSupprSite(event): void {
            setIdSite(event.detail);
            setShowModal(true);
        }

        function onModifiedSiteInformation(event): void {
            const site: InterventionSite = event.detail as InterventionSite;
            const idSite: number = site.id;
            const nom: string = site.nomSiteIntervention === "" ? null : site.nomSiteIntervention;
            const location: string = site.locationSiteIntervention === "" ? null : site.locationSiteIntervention;
            const {latitude, longitude} = site;


            fetchAPI.updateInterventionSite(idSite, nom, location,latitude, longitude, authObject.jwt)
                .then(response => {
                    flash(5000, "success", "L'employé à bien été modifié.");
                });


        }



        document.addEventListener("onModifiedSiteInformation", onModifiedSiteInformation);
        document.addEventListener("onChangeSite", onChangeSite);
        document.addEventListener("onSupprSite", onSupprSite);

        return () => {
            document.removeEventListener("onChangeSite", onChangeSite);
            document.removeEventListener("onSupprSite", onSupprSite);
            document.removeEventListener("onModifiedSiteInformation", onModifiedSiteInformation);
        }
    }, []);

    function handlingSuppressSite() {
        fetchAPI.deleteSiteIntervention(idSite, authObject.jwt)
            .then(response => {
                flash(5000, "success", "Le site d'intervention à bien été supprimer.");
                setIdSite(null);
                setShowModal(false);
                setSiteInterventions(siteInterventions.filter(site => site.id !== idSite));
            });
    }

    function createInterventionSite() {
        navigate("/creationInterventionSite", {replace: true});
    }

    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">
                        <>
                            <Card>
                                <Card.Body style={{display: "flex", justifyContent: "center"}}>
                                    <Button
                                        variant="success"
                                        onClick={createInterventionSite}>Enregistrer un nouveau site d'intervention
                                    </Button>
                                </Card.Body>
                            </Card>
                            <TableSiteIntervention sites={siteInterventions}></TableSiteIntervention>
                        </>


                    </div>

                    <ModalConfirmation fonctionBtnAccept={handlingSuppressSite}
                                       fonctionBtnRefuse={() => setShowModal(false)} show={showModal}
                                       titre="Confirmation supression site d'intervention"
                                       body={
                                           <p>
                                               Voulez vous réelement suprimer ce site ? (identifiant : {idSite})
                                           </p>}>
                    </ModalConfirmation>

                    <OnlineDetect></OnlineDetect>
                </PersistGate>
            </Provider>

        </>
    )
}
