import {useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import * as React from 'react';
import {persistor, store} from "../store";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import {Button, Card, CardHeader} from "react-bootstrap";
import {Auth, IconButton, InputForm, User, UserGestion} from "../types/type";
import {DataAllUser} from "../types/loaderType";
import CustomTable from "../Components/Table/CustomTable";
import {iconEdit, iconTrash} from "../types/Icons";
import {MDBIcon} from "mdb-react-ui-kit";
import PaginationTable from "../Components/PaginationTable";
import {getEmptyUserGestion, getNumberOfPages} from "../Utils/functionManager";
import CustomForm from "../Components/Forms/CustomForm";
import ModalConfirmation from "../Components/ModalConfirmation";
import {patternLogin, patternName, patternPassword} from "../types/regexPattern";
import {fetchAPI} from "../Components/API";
import {flash} from "react-universal-flash";

const MAX_ITEMS_BY_PAGES = 6;

export default function GestionUser(): React.ReactElement {

    const navigate = useNavigate();
    const location = useLocation();

    const auth: Auth = fetchAPI.authObject;

    const [showModalUpdateC, setShowModalUpdateC] = React.useState<boolean>(false);
    const [showModalCreateC, setShowModalCreateC] = React.useState<boolean>(false);

    const [showModalUpdateE, setShowModalUpdateE] = React.useState<boolean>(false);
    const [showModalCreateE, setShowModalCreateE] = React.useState<boolean>(false);

    const [showModalDelete, setShowModalDelete] = React.useState<boolean>(false);


    const [targetUser, setTargetUser] = React.useState<UserGestion>(getEmptyUserGestion());


    const {clients, employes} = useLoaderData() as DataAllUser;


    const [idEmploye, setIdEmploye] = React.useState<boolean>(null);

    const [key, setKey] = React.useState<number>(1);

    const [wantEmploye, setWantEmploye] = React.useState<boolean>(true);


    /**
     * Pagination
     */
    const nbPages = wantEmploye ? getNumberOfPages(employes, MAX_ITEMS_BY_PAGES) : getNumberOfPages(clients, MAX_ITEMS_BY_PAGES);
    const [currentPage, setCurrentPage] = React.useState(1);


    /**
     * Définition des formulaires et de la table
     */

    const headers: string[] = ["Identifiant", "Nom", "Prénom"];
    const keys: string[] = ["id", "lastName", "firstName"];


    const actions: IconButton[] = [
        {
            icon: iconEdit,
            nameEventClick: "update"
        },
        {
            icon: iconTrash,
            nameEventClick: "delete"
        }
    ]

    const inputsFormCreate: InputForm[] = [
        {
            name: "lastName",
            label: "Nom de l'utilisateur",
            type: "text",
            pattern: patternName,
            value: targetUser.lastName,
            setValue: (lastName: object) => setTargetUser({...targetUser, ...lastName})
        },
        {
            name: "firstName",
            label: "Prénom de l'utilisateur",
            type: "text",
            pattern: patternName,
            value: targetUser.firstName,
            setValue: (firstName: object) => setTargetUser({...targetUser, ...firstName})
        },
        {
            name: "login",
            label: "Login de l'utilisateur",
            type: "text",
            pattern: patternLogin,
            value: targetUser.login,
            setValue: (login: object) => setTargetUser({...targetUser, ...login})
        },
        {
            name: "pwd",
            label: "Mot de passe",
            type: "password",
            pattern: patternPassword,
            value: targetUser.pwd,
            setValue: (pwd: object) => setTargetUser({...targetUser, ...pwd})
        },
        {
            name: "pwdConfirm",
            label: "Confirmation du mot de passe",
            type: "password",
            pattern: patternPassword,
            value: targetUser.pwdConfirm,
            setValue: (pwdConfirm: object) => setTargetUser({...targetUser, ...pwdConfirm})
        },
        {
            name: "action",
            label: "Action",
            type: "hidden",
            value: "create",
            setValue: (o: object) => {
            }
        },
        {
            name: "objectType",
            label: "ObjectType",
            type: "hidden",
            value: wantEmploye ? "employe" : "client",
            setValue: (o: object) => {
            }
        }
    ]

    const inputsFormUpdate: InputForm[] = [
        {
            name: "lastName",
            label: "Nom de l'utilisateur",
            type: "text",
            pattern: patternName,
            value: targetUser.lastName,
            setValue: (lastName: object) => setTargetUser({...targetUser, ...lastName})
        },
        {
            name: "firstName",
            label: "Prénom de l'utilisateur",
            type: "text",
            pattern: patternName,
            value: targetUser.firstName,
            setValue: (firstName: object) => setTargetUser({...targetUser, ...firstName})
        },
        {
            name: "login",
            label: "Login de l'utilisateur",
            type: "text",
            pattern: patternLogin,
            value: targetUser.login,
            setValue: (login: object) => setTargetUser({...targetUser, ...login})
        },
        {
            name: "pwd",
            label: "Mot de passe",
            type: "password",
            pattern: patternPassword,
            required: false,
            value: targetUser.pwd,
            setValue: (pwd: object) => setTargetUser({...targetUser, ...pwd})
        },
        {
            name: "pwdConfirm",
            label: "Confirmation du mot de passe",
            type: "password",
            required: false,
            pattern: patternPassword,
            value: targetUser.pwdConfirm,
            setValue: (pwdConfirm: object) => setTargetUser({...targetUser, ...pwdConfirm})
        },
        {
            name: "action",
            label: "Action",
            type: "hidden",
            value: "update",
            setValue: (o: object) => {
            }
        },
        {
            name: "id",
            label: "IdUser",
            type: "hidden",
            value: targetUser.id,
            setValue: (o: object) => {
            }
        },
        {
            name: "objectType",
            label: "ObjectType",
            type: "hidden",
            value: wantEmploye ? "employe" : "client",
            setValue: (o: object) => {
            }
        }
    ];


    if (!wantEmploye) {
        headers.push("Client pro");
        keys.push("isPro");
        inputsFormCreate.push(
            {
                name: "isPro",
                label: "Client pro ?",
                type: "checkbox",
                required: false,
                value: targetUser.isPro,
                setValue: (isPro: object) => setTargetUser({...targetUser, ...isPro})
            }
        );

        const clientsPro: User[] = clients.filter(user => user.roles.includes("ROLE_CLIENTPRO"));

        inputsFormUpdate.push(
            {
                name: "boss",
                label: "Chef",
                type: "select",
                selectItems: clientsPro.map(user => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id.toString()
                })),
                value: targetUser.boss ? targetUser.boss.id : "",
                setValue: (idBoss: object) => setTargetUser({...targetUser, ...idBoss})
            },
            {
                name: "isPro",
                label: "Client pro ?",
                type: "checkbox",
                required: false,
                value: targetUser.isPro,
                setValue: (isPro: object) => setTargetUser({...targetUser, ...isPro})
            }
        )

    }


    React.useEffect(() => {
        closeAllModals();
    }, [clients, employes]);


    function closeAllModals() {
        if (showModalCreateC) setShowModalCreateC(false);
        if (showModalUpdateC) setShowModalUpdateC(false);

        if (showModalDelete) setShowModalDelete(false);

        if (showModalCreateE) setShowModalCreateE(false);
        if (showModalUpdateE) setShowModalUpdateE(false);
    }

    function handlerClickTable(eventName: string, object: User): void {
        setTargetUser({
            ...object,
            pwd: "",
            pwdConfirm: ""
        });
        if (eventName === "delete") setShowModalDelete(true);
        if (eventName === "update") wantEmploye ? setShowModalUpdateE(true) : setShowModalUpdateC(true);
    }

    async function acceptDelete() {
        const response = await fetchAPI.deleteUser(targetUser.id, auth.jwt);
        console.log(response);
        if (response.status === 204) {
            flash(5000, "success", "L'utilisateur à bel et bien été supprimé.");
            navigate(location.pathname);
        } else {
            flash(5000, "danger", "Une erreur est survenue : " + response.statusText);
        }
    }


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">

                        <Card>
                            <CardHeader style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "large",
                                fontWeight: "600"
                            }}>
                                <p style={{
                                    margin: "0",
                                    alignSelf: "center"
                                }}>{wantEmploye ? "Gestion employes" : "Gestion client"}</p>
                                <Button onClick={() => {
                                    setTargetUser(getEmptyUserGestion());
                                    if (wantEmploye) setShowModalCreateE(true);
                                    else setShowModalCreateC(true);
                                }}>
                                    <MDBIcon style={{marginRight: "0.3vw"}} fas icon="plus"></MDBIcon>
                                    {wantEmploye ? "Nouveau employé" : "Nouveau client"}
                                </Button>
                            </CardHeader>
                        </Card>


                        <div className="mb-3" style={{display: "flex", justifyContent: "center"}}>
                            <Button className="mx-3" onClick={() => setWantEmploye(true)}
                                    variant={wantEmploye ? "primary" : "secondary"}>Employes</Button>
                            <Button className="mx-3" onClick={() => setWantEmploye(false)}
                                    variant={wantEmploye ? "secondary" : "primary"}>Client</Button>
                        </div>

                        <CustomTable<User> headers={headers} keys={keys} keyForKeyAttribute="id"
                                           objects={wantEmploye ? employes.slice((currentPage - 1) * MAX_ITEMS_BY_PAGES, currentPage * MAX_ITEMS_BY_PAGES) : clients.slice((currentPage - 1) * MAX_ITEMS_BY_PAGES, currentPage * MAX_ITEMS_BY_PAGES)}
                                           handlerClick={handlerClickTable} actions={actions}></CustomTable>

                        <PaginationTable style={{justifyContent: "center"}} nbPages={nbPages} currentPage={currentPage}
                                         setCurrentPage={setCurrentPage}></PaginationTable>

                    </div>


                    <ModalConfirmation
                        form={true}
                        titre="Création d'un client"
                        fonctionBtnRefuse={() => setShowModalCreateC(false)}
                        show={showModalCreateC}
                        body={
                            <CustomForm titleButtonSend="Créer un client" inputs={inputsFormCreate}></CustomForm>
                        }>

                    </ModalConfirmation>

                    <ModalConfirmation
                        form={true}
                        titre="Création d'un employé"
                        fonctionBtnRefuse={() => setShowModalCreateE(false)}
                        show={showModalCreateE}
                        body={
                            <CustomForm titleButtonSend="Créer un employé" inputs={inputsFormCreate}></CustomForm>
                        }>

                    </ModalConfirmation>

                    <ModalConfirmation
                        body={
                            <p>
                                Voulez vous vraiment
                                supprimer {wantEmploye ? "l'employé " : "le client "} : {targetUser ? targetUser.firstName + " " + targetUser.lastName : ""}
                            </p>
                        }
                        titre="Confirmation de la supression de l'utilisateur"
                        fonctionBtnRefuse={() => setShowModalDelete(false)}
                        show={showModalDelete} fonctionBtnAccept={acceptDelete}>
                    </ModalConfirmation>

                    <ModalConfirmation
                        form={true}
                        body={
                            <CustomForm titleButtonSend="Modifier l'employé" inputs={inputsFormUpdate}></CustomForm>
                        }
                        titre="Modification de l'employé" fonctionBtnRefuse={() => setShowModalUpdateE(false)}
                        show={showModalUpdateE}>
                    </ModalConfirmation>

                    <ModalConfirmation
                        form={true}
                        body={
                            <CustomForm titleButtonSend="Modifier le client" inputs={inputsFormUpdate}></CustomForm>
                        }
                        titre="Modification du client" fonctionBtnRefuse={() => setShowModalUpdateC(false)}
                        show={showModalUpdateC}>
                    </ModalConfirmation>


                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>

        </>
    );
}
