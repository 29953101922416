import * as React from 'react';
import {Button, FormCheck, Table} from "react-bootstrap";
import {CustomTableProps, IconButton, IconMDB} from "../../types/type";
import {MDBIcon} from "mdb-react-ui-kit";
import ButtonIcon from "./tableParts/ButtonIcon";
import {useEffect} from "react";

export default function CustomTable<T>(
    {headers, keys, keyForKeyAttribute, objects, actions, handlerClick}: CustomTableProps<T>
): React.ReactElement {

    useEffect(() => {

    }, [objects]);

    return (
        <Table responsive={true}>
            <thead className="table-dark">
            <tr>
                {headers.map((header) => (
                    <th key={header}>{header}</th>
                ))}
                <th>Actions</th>
            </tr>
            </thead>
            <tbody style={{verticalAlign: "middle"}}>
            {objects.map((object: T, index: number) => {
                const render: React.ReactElement[] = [];

                let i = 0;

                for (let key of keys) {
                    let renderRow: React.ReactElement = <td key={`${object[key]}_${headers[i]}_${index}`}>{object[key]}</td>
                    if (typeof object[key] === "boolean") {
                        renderRow =
                            <td key={`${object[key]}_${headers[i]}_${index}`}>
                                <FormCheck className="formCheckCustomTable" type="switch" checked={object[key]} readOnly={true}></FormCheck>
                            </td>
                    }
                    i++;
                    render.push(renderRow);
                }

                return (
                    <tr key={object[keyForKeyAttribute]}>
                        {render}
                        <td>
                            {actions.map((action: IconButton) => (
                                <ButtonIcon handlerClick={() => handlerClick(action.nameEventClick, object)} key={action.icon.name} icon={action.icon}></ButtonIcon>
                            ))}
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    )
}


/*
<td>
                            {buttons.map((button) => (
                                <Button className="buttonCustomTable" key={button.nameEventClick} variant={button.variant} onClick={() => handlerClick(button.nameEventClick, object)}>
                                    <MDBIcon far={button.icon.far} fas={button.icon.fas} icon={button.icon.name}/>
                                    {button.title}
                                </Button>
                            ))}
                        </td>
 */
