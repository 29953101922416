import {ReactElement} from "react";


interface AppointmentChoice {
    typeRdv: string,
    duration: number,
    idSite: number,
    idService: number
}

interface ErrorPageProps {
    titleError: string,
    bodyError: string,
    codeError: number
}

interface User {
    id: number,
    firstName: string,
    lastName: string,
    login: string,
    roles: string[],
    boss: User | undefined,
    isPro: boolean,
    isEmploye: boolean,
}

interface UserGestion {
    id: number,
    firstName: string,
    lastName: string,
    boss: User | undefined,
    isPro: boolean,
    isEmploye: boolean,
    login: string,
    pwd: string,
    pwdConfirm: string
}

interface InterventionSite {
    id: number,
    locationSiteIntervention: string,
    nomSiteIntervention: string,
    longitude: number,
    latitude: number
}

interface Appointment {
    idRdv: number,
    sujet: string,
    typeRdv: string,
    date: Date,
    horaires: string[],
    client: Client,
    messages: ChatMessage[],
    horaireDepart: string,
    horaireFin: number,
    siteIntervention: InterventionSite,
    signature: Signature,
    service: Service
    chef: User,
    employe: Client
}

interface LoaderDataUpdateAppointment {
    rdvModif: Appointment,
    interventionSites: InterventionSite[],
    services: Service[]
}


interface EventPlanning {
    title: string,
    start: string,
    end: string,
    idRdv: number,
    description: string,
    horaires: string[],
    typeRdv: string,
    dateRdv: Date,
    client: Client,
    messages: ChatMessage[],
    signature: Signature,
    siteIntervention: InterventionSite
}

interface Client {
    id: number,
    nom: string,
    prenom: string,
    roles: string[]
}

interface ChatMessage {
    id: number
    message: string,
    imagePath?: string,
    client: Client,
    appointment: {
        id: number
    },
    date: Date,
    horaire: string
}

interface UserConnection {
    loginUser: string,
    pwdUser: string
}

interface Signature {
    timeString: string,
    hours: number,
    minute: number,
    dateSignature: Date,
    latitude: number,
    longitude: number,
    nameSignature: string,
    imagePath: string
}

interface ErrorAPI {
    code: number,
    message: string
}

interface Service {
    idService: number,
    name: string,
    duration: number,
    description: string,
    price: number,
    status: boolean,
    durationString: string
}


interface Adress {
    cityName: string
    coordinates: number[]
}

interface Auth {
    jwt: string,
    isAdmin: boolean,
    isEmploye: boolean,
    isClientPro: boolean,
    identifiant: string,
    username: string,
    isConnected: boolean

}

interface ButtonTable {
    name: string,
    eventName: string,
    variant: string
}

interface IconMDB {
    name: string,
    far: boolean,
    fas: boolean
}

interface IconButton {
    icon: IconMDB,
    nameEventClick: string
}

interface ModalConfirmationProps {
    titre: string,
    fonctionBtnAccept?: () => void,
    fonctionBtnRefuse: () => void,
    show: boolean,
    body: ReactElement,
    form?: boolean
}

interface CustomFormProps {
    inputs: InputForm[],
    titleButtonSend: string
}
interface InputForm {
    name: string,
    label: string
    type: string,
    value: any,
    setValue: any,
    pattern?: string
    required?: boolean,
    selectItems?: SelectItem[]
}

interface SelectItem {
    label: string,
    value: string
}


interface CustomTableProps<T> {
    headers: string[],
    keys: string[],
    keyForKeyAttribute: string,
    objects: T[],
    actions: IconButton[]
    handlerClick: (eventName: string, object: T) => void
}

interface DataActionService {
    service: Service,
    authObject: Auth,
}

interface DataVisio {
    sid: string,
    data: {
        type: string,
        candidate?: RTCIceCandidateInit
    }
}

interface LoaderDataGestionInterventionSite {
    action: string,
    interventionSite?: InterventionSite,
    idSite?: number
}
function isValidErrorAPI(object: any): object is ErrorAPI {
    return object.code && object.message;
}


export {
    UserGestion,
    ErrorPageProps,
    LoaderDataUpdateAppointment,
    LoaderDataGestionInterventionSite,
    DataActionService,
    InputForm,
    CustomFormProps,
    Appointment,
    InterventionSite,
    User,
    AppointmentChoice,
    ErrorAPI,
    isValidErrorAPI,
    EventPlanning,
    Service,
    Adress,
    Auth,
    ButtonTable,
    CustomTableProps,
    IconMDB,
    IconButton,
    ModalConfirmationProps,
    Signature,
    UserConnection,
    DataVisio,
    ChatMessage
}
