// reducers/authReducer.js
import {jwtDecode} from "jwt-decode";

const initialState = {
    jwt: undefined,
    isAdmin: false,
    isEmploye: false,
    isClientPro: false,
    identifiant: "",
    username: "",
    isConnected: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_JWT':
            let decodedJwt = jwtDecode(action.payload);
            return {
                ...state,
                jwt: action.payload,
                isAdmin: decodedJwt.roles.includes("ROLE_ADMIN"),
                isEmploye: decodedJwt.roles.includes("ROLE_EMPLOYE"),
                isClientPro: decodedJwt.roles.includes("ROLE_CLIENTPRO"),
                username: decodedJwt.username,
                identifiant: decodedJwt.identifiant,
                isConnected: true
            };
        case 'LOGOUT':
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
