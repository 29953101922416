import {IconMDB} from "./type";

export const iconHome: IconMDB = {
    fas: true,
    far: false,
    name: "home"
}

export const iconList: IconMDB = {
    far: false,
    fas: true,
    name: "list"
}

export const iconEditUser: IconMDB = {
    far: false,
    fas: true,
    name: "user-edit"
}

export const iconCalendarPlus: IconMDB = {
    far: false,
    fas: true,
    name: "calendar-plus"
}

export const iconSignOut: IconMDB = {
    name: "sign-out-alt",
    far: false,
    fas: true,
}

export const iconHandShake: IconMDB = {
    far: true,
    fas: false,
    name: "handshake"
}

export const iconHistory: IconMDB = {
    far: false,
    fas: true,
    name: "history"
}

export const iconMapMarker: IconMDB = {
    far: false,
    fas: true,
    name: "map-marker-alt"
}

export const iconUser: IconMDB = {
    far: false,
    fas: true,
    name: "user"
}

export const iconEdit: IconMDB = {
    fas: true,
    far: false,
    name: "edit"
}

export const iconTrash: IconMDB = {
    fas: true,
    far: false,
    name: "trash-alt"
}

export const iconClone: IconMDB = {
    far: true,
    fas: false,
    name: "copy"
}

const dangerIcon: IconMDB = {
    fas: true,
    far: false,
    name: "exclamation-triangle"
}

const successIcon: IconMDB = {
    fas: true,
    far: false,
    name: "check-circle"
}

const warningIcon: IconMDB = {
    fas: true,
    far: false,
    name: "exclamation-circle"
}

const infoIcon: IconMDB = {
    fas: true,
    far: false,
    name: "info-circle"
}

export const typeFlashToIcon: Map<string, IconMDB> = new Map([
    ["danger", dangerIcon],
    ["success", successIcon],
    ["warning", warningIcon],
    ["info", infoIcon]
])
