import {Provider, useDispatch} from "react-redux";
import * as React from "react";
import {persistor, store} from "../store";
import {Button, Card, Container, Form, Nav, Navbar, NavDropdown, Pagination} from "react-bootstrap";
import ModalConfirmation from "../Components/ModalConfirmation";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {useLoaderData, useNavigate} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {fetchAPI} from "../Components/API";
import {flash} from "react-universal-flash";
import RdvDetail from "../Components/RdvDetail";
import {Typeahead} from "react-bootstrap-typeahead";
import {MDBIcon} from "mdb-react-ui-kit";
import {getNumberOfPages} from "../Utils/functionManager";
import PaginationTable from "../Components/PaginationTable";
import {DataUserRdvsAndServices} from "../types/loaderType";
import {Appointment, Service} from "../types/type";
import ElementContainer from "../Components/ElementContainer";

const MAX_ELEMENT_PAGINATION: number = 5;

let idRdvSuppr,
    rdvChat;
export default function PageCompte(): React.ReactElement {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [nbPages, setNbPages] = React.useState<number>();
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    /* Page JWT mécanisme */

    const [isChat, setIsChat] = React.useState<boolean>(false);
    const [isModal, setIsModal] = React.useState<boolean>(false);
    const [render, setRender] = React.useState<React.ReactElement[]>([]);
    const [initialRender, setInitialRender] = React.useState<React.ReactElement[]>([]);
    const [selected, setSelected] = React.useState<React.ReactElement[]>([]);

    const data = useLoaderData() as DataUserRdvsAndServices;
    const authObject = fetchAPI.authObject;

    const rdvsData: Appointment[] = data.rdvs;
    const servicesData: Service[] = data.services;


    React.useEffect(() => {
        setNbPages(getNumberOfPages(rdvsData, MAX_ELEMENT_PAGINATION));

        getRdvsUser(rdvsData).then();
    }, [rdvsData]);

    function removeRdv() {
        setIsModal(false);
        fetchAPI.deleteRdv(idRdvSuppr, authObject.jwt)
            .then(response => {
                flash(5000, "success", "Le rendez-vous à bien été annulé.");
                //document.getElementById(idRdvSuppr).remove();
                const indexRemove = rdvsData.findIndex(appointment => appointment.idRdv === idRdvSuppr);
                rdvsData.splice(indexRemove, 1);
                getRdvsUser(rdvsData).then();
            });
    }


    async function getRdvsUser(rdvs: Appointment[]) {
        let tabRender = [];

        for (let rdv of rdvs) {
            let dateObject = rdv.date;
            let dateNow = new Date();

            dateObject.setHours(rdv.horaireFin);

            let horaire = "à -1h";

            let horaires = rdv.horaires;

            if (horaires.length > 1) {
                horaire = `de ${horaires[0]}h à ${rdv.horaireFin}h`;
            } else horaire = `à ${horaires[0]}h`;

            rdv.date.setHours(Number(rdv.horaireDepart));


            const cardElement: React.ReactElement = <Card
                                      key={rdv.idRdv} className="text-center">
                <Card.Header>Rendez-vous
                    du {dateObject.toLocaleDateString('fr-Fr', {weekday: 'long'})} {dateObject.toLocaleDateString()} {horaire}</Card.Header>
                <Card.Body>


                    <Card.Title>{rdv.service.name}</Card.Title>

                    <div>

                        <MDBIcon style={{marginRight: "0.2%"}} far icon="clock"/>
                        {`${rdv.service.durationString}`}


                        <MDBIcon style={{marginRight: "0.2%", marginLeft: "1%"}} fas icon="money-bill-wave"/>
                        {`${rdv.service.price} €`}


                    </div>


                    <Card.Text>
                        Motif : {rdv.sujet}
                    </Card.Text>

                    {dateNow < dateObject &&
                        <div>
                            <Button variant="danger" className="mx-3" onClick={() => {
                                setIsModal(true);
                                idRdvSuppr = rdv.idRdv;
                            }}>Annuler</Button>
                            <Button variant="primary" className="mx-3" onClick={() => {
                                setIsChat(true);
                                rdvChat = rdv;
                            }}>Chat</Button>
                        </div>
                    }

                </Card.Body>
                <Card.Footer className="text-muted">Lieu
                    : {rdv.siteIntervention.locationSiteIntervention}</Card.Footer>
            </Card>


            tabRender.push(
                <ElementContainer element={cardElement} data={
                    {
                        idService: rdv.service.idService,
                        date:rdv.date,
                        sujet:rdv.sujet,
                        horairefin:rdv.horaireFin
                    }
                }>
                </ElementContainer>

            );
        }

        setRender(tabRender);
        setInitialRender(tabRender);
    }


    function sortPastAppointment() {
        setCurrentPage(1);
        setRender([...initialRender.filter((objet) => {
            const dateTemp = new Date(objet.props.data.date.getTime());
            dateTemp.setHours(objet.props.data.horairefin);
            if (dateTemp <= new Date()) return objet;
        })]);
    }

    function sortPostAppointment() {
        setCurrentPage(1);
        setRender([...initialRender.filter((objet) => {
            const dateTemp = new Date(objet.props.data.date.getTime());
            dateTemp.setHours(objet.props.data.horairefin);
            if (dateTemp >= new Date()) return objet;
        })]);
    }

    function searchAppoitment(e) {
        setCurrentPage(1);
        setRender([e]);
    }

    function filterSearchCard(subjectSearch: string, e: any) {
        if (subjectSearch.length >= 3) {
            setCurrentPage(1);
            setRender(initialRender.filter((objet) => objet.props.data.sujet.toLowerCase().includes(subjectSearch.toLowerCase())));
        } else {
            setRender(initialRender);
        }
    }

    function sortByService(idService: number) {
        setRender(initialRender.filter((objet) => objet.props.data.idService === idService));
    }

    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">

                        {isChat ?
                            <RdvDetail jwt={authObject.jwt} rdv={rdvChat} idUser={Number(authObject.identifiant)}
                                       isEmploye={authObject.isEmploye}></RdvDetail>
                            :
                            <div>
                                <Navbar style={{marginBottom: "5vh"}} expand="lg" className="bg-body-tertiary">
                                    <Container fluid>
                                        <Navbar.Brand href="#">Outils</Navbar.Brand>
                                        <Navbar.Toggle aria-controls="navbarScroll"/>
                                        <Navbar.Collapse role="list" id="navbarScroll"
                                        >
                                            <Nav
                                                className="me-auto my-2 my-lg-0"
                                                style={{maxHeight: '100px'}}
                                                navbarScroll
                                            >
                                                <NavDropdown title="Trier par date"
                                                             id="navbarScrollingDropdownDate">
                                                    <NavDropdown.Item
                                                        onClick={() => setRender(initialRender)}>Tous</NavDropdown.Item>
                                                    <NavDropdown.Divider/>
                                                    <NavDropdown.Item
                                                        onClick={sortPastAppointment}>Passés</NavDropdown.Item>
                                                    <NavDropdown.Item onClick={sortPostAppointment}>Pas encore
                                                        arrivé</NavDropdown.Item>
                                                </NavDropdown>

                                                <NavDropdown title="Trier par type"
                                                             id="navbarScrollingDropdownType">
                                                    <NavDropdown.Item
                                                        onClick={() => setRender(initialRender)}>Tous</NavDropdown.Item>
                                                    <NavDropdown.Divider/>

                                                    {servicesData.map((service) => (
                                                        <NavDropdown.Item key={service.idService}
                                                                          onClick={() => sortByService(service.idService)}>{service.name}</NavDropdown.Item>
                                                    ))}
                                                </NavDropdown>
                                            </Nav>
                                            <PaginationTable style={{marginBottom: "0", marginRight: "25vw"}}
                                                             nbPages={nbPages} currentPage={currentPage}
                                                             setCurrentPage={setCurrentPage}></PaginationTable>
                                            <Form className="d-flex">
                                                <Typeahead
                                                    onInputChange={filterSearchCard}
                                                    highlightOnlyResult
                                                    selected={selected}
                                                    onChange={searchAppoitment}
                                                    minLength={3}
                                                    placeholder="Chercher un rendez-vous"
                                                    id="basic-typeahead-single"
                                                    labelKey={(option) => `${option.props.data.sujet} ${option.props.data.date.toLocaleString()}`}
                                                    filterBy={(option, props) => option.props.data.sujet.toLowerCase().includes(props.text.toLowerCase())}
                                                    options={initialRender}
                                                ></Typeahead>
                                            </Form>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                                {render.slice((currentPage - 1) * MAX_ELEMENT_PAGINATION, currentPage * MAX_ELEMENT_PAGINATION)}
                            </div>
                        }


                    </div>


                    <OnlineDetect></OnlineDetect>

                    <ModalConfirmation show={isModal} body={<p>Êtes vous sûr de vouloir supprimer ce rendez vous ?</p>}
                                       titre="Annulation de rendez-vous" fonctionBtnRefuse={() => setIsModal(false)}
                                       fonctionBtnAccept={removeRdv}></ModalConfirmation>

                </PersistGate>
            </Provider>

        </>
    )
}
