import * as React from "react";

//Elements graphiques
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import timeGridPlugin from '@fullcalendar/timegrid'
import frLocale from '@fullcalendar/core/locales/fr';
import 'bootstrap/dist/css/bootstrap.css';
import {useMediaQuery} from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {fetchAPI} from "./API";
import {useDispatch} from "react-redux";
import {MoonLoader} from "react-spinners";
import {Appointment, ErrorAPI, EventPlanning, isValidErrorAPI} from "../types/type";
import ErrorJWT from "../Errors/ErrorJWT"; // needs additional webpack config


let firstDayWeek;

export default function PlanningOld(
    {jwt, idEmploye}: {
        jwt: string,
        idEmploye: number
    }
): React.ReactElement {

    const dispatch = useDispatch();

    const [tabEvents, setTabEvents] = React.useState<EventPlanning[]>([]);

    const isMobile: boolean = useMediaQuery({query: `(max-width: 760px)`});

    const headerTools: {
        right: string,
        left: string,
        center: string
    } = {
        right: "",
        left: "",
        center: ""
    };

    const footerTools: {
        right: string,
        left: string,
        center: string
    } = {
        right: "",
        left: "",
        center: ""
    };

    const [id, setId] = React.useState<number>(0);

    const [loading, setLoading] = React.useState<boolean>(true);


    function launchEvent(data) {
        const clickRdv = new CustomEvent("onRdvClick", {detail: data});
        document.dispatchEvent(clickRdv);
    }

    function startLoading() {
        setLoading(true);
        document.body.style.opacity = "0.5";
    }

    function stopLoading() {
        setLoading(false);
        document.body.style.opacity = "1";
    }

    React.useEffect(() => {
        document.querySelector(".fc-next-button").addEventListener("click", async () => {
            await setRdvs();
        });
        document.querySelector(".fc-prev-button").addEventListener("click", async () => {
            await setRdvs();
        });
        setRdvs().then();

    }, []);

    async function getRdvTakenForWeek(day: Date): Promise<Appointment[]> {

        let rdvs: Appointment[] | ErrorAPI = await fetchAPI.getRdvForWeek(day.toISOString().slice(0, 10), jwt);

        if (isValidErrorAPI(rdvs)) throw new ErrorJWT("", rdvs.code);

        return rdvs;
    }


    async function getHoraireRdvTaken(): Promise<Appointment[]> {

        firstDayWeek = document.querySelector("th [data-date]");

        let rdvTakens: Appointment[] = [];

        let date = new Date(firstDayWeek.dataset.date);

        try {
            let rdvs = await getRdvTakenForWeek(date);

            rdvTakens = rdvs.filter(objet => objet.employe && objet.employe.id == idEmploye);

        } catch (err: unknown) {
            if (err instanceof ErrorJWT) {
                err.flashError();
                dispatch({type: 'LOGOUT'});
            }
        }

        return rdvTakens;
    }


    async function setRdvs(): Promise<void> {
        startLoading();

        let rdvTakens: Appointment[] = await getHoraireRdvTaken();

        let tabEventsTemp = [...tabEvents];


        for (let rdv of rdvTakens) {
            const dateStart = new Date(rdv.date);
            const dateEnd = new Date(rdv.date);

            dateStart.setHours(parseInt(rdv.horaires[0]));
                dateEnd.setHours(Number(rdv.horaires[rdv.horaires.length - 1]) + 1);

                tabEventsTemp.push({
                    title: `${rdv.client.prenom} ${rdv.client.nom}`,
                    start: dateStart.toISOString(),
                    end: dateEnd.toISOString(),
                    idRdv: rdv.idRdv,
                    description: rdv.sujet,
                    horaires: rdv.horaires,
                    typeRdv: rdv.typeRdv,
                    dateRdv: rdv.date,
                    client: {
                        id: rdv.client.id,
                        nom: rdv.client.nom,
                        prenom: rdv.client.prenom,
                        roles: rdv.client.roles
                    },
                    messages: rdv.messages,
                    signature: rdv.signature,
                    siteIntervention: rdv.siteIntervention
                });


        }
        setTabEvents(tabEventsTemp);
        stopLoading();
    }

    function handlerDateClicked(date) {
        console.log(date.dateStr)
        /*
        setTabEvents([...tabEvents, {
            title: `Test event ${id}`,
            date: date.dateStr,
            allDay: true
        }]);
        setId(id + 1)
        console.log(tabEvents);
         */
    }

    function handlerEventClicked(infos) {
        launchEvent(infos.event);
    }

    if (!isMobile) {
        headerTools.right = 'timeGridDay,timeGridWeek';
        headerTools.left = 'today,prev,next';
        headerTools.center = 'title';
    }else {
        footerTools.center = 'timeGridDay,timeGridWeek';
        headerTools.right = 'title';
        headerTools.center = '';
        headerTools.left = 'today,prev,next';
    }


    return (
        <>
            <MoonLoader id="loader" cssOverride={{display:"block", position:"absolute", left:"50%", top:"50%", opacity: "1"}} color="#36d7b7" loading={loading} size={80}/>
            <FullCalendar
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, bootstrap5Plugin]}
                initialView="timeGridWeek"
                themeSystem="bootstrap5"
                headerToolbar={headerTools}
                footerToolbar={footerTools}
                weekends={false}
                locale={frLocale}
                events={tabEvents}
                dateClick={handlerDateClicked}
                eventClick={handlerEventClicked}
            />
        </>

    );

}
