import {ReactElement, useEffect, useState} from "react";
import * as React from "react";

//Elements graphiques

import {
    MDBIcon,
    MDBListGroup,
    MDBNavbarNav,

} from "mdb-react-ui-kit";
import {
    MDBCollapse,
    MDBContainer,
    MDBNavbar, MDBNavbarBrand,
    MDBNavbarToggler, MDBNavItem
} from "mdbreact";

import servitysLogo from '../../img/servitys.png';
import Link from './Link'
import {IconMDB} from "../../types/type";
import {
    iconCalendarPlus,
    iconEditUser,
    iconHandShake,
    iconHistory,
    iconHome,
    iconList,
    iconMapMarker, iconSignOut, iconUser
} from "../../types/Icons";
import {
    APPOINTMENTS_HISTORY,
    EMPLOYEE_APPOINTMENTS,
    HOME, LOGOUT,
    SERVICES_MANAGEMENT,
    TAKE_APPOINTMENT,
    USER_APPOINTMENTS,
    USERS_MANAGEMENT
} from "./LinksName";
import {useLocation, useNavigate} from "react-router-dom";
import ModalConfirmation from "../ModalConfirmation";

/**
 * @description Component displaying a side navigation bar
 * @param jwt The jwt retrieved when the user logs in
 * @param isAdmin Boolean indicating whether the current user is an administrator
 * @param isEmploye Boolean indicating whether the current user is an employee
 * @param username The pseudonym of the logged-in user
 * @param isClientPro Boolean indicating whether the current user is a corporate customer
 * @return {Element} A React element that displays a navigation bar
 * @constructor
 */
export default function NavigationSideBar({jwt, isAdmin, isEmploye, username, isClientPro}): ReactElement {

    const [showShow, setShowShow] = useState<boolean>(false);

    const navigate = useNavigate();

    const [showModalLogout, setShowModalLogout] = React.useState<boolean>(false);

    const location = useLocation();

    const toggleShow = () => setShowShow(!showShow);

    function logout() {
        navigate("/logout");
    }

    React.useEffect(() => {
        setShowShow(false);
    }, [location.pathname]);


    return (
        <>
            <MDBCollapse tag="nav" className={`d-lg-block bg-white sidebar ${showShow ? 'show' : ''}`}>
                <div className="position-sticky">
                    <MDBListGroup flush="true" className="mx-3 mt-4">
                        <Link route="/" name={HOME} icon={iconHome}></Link>
                        {jwt ?
                            isEmploye ?
                                <Link route="/rdvListe" name={EMPLOYEE_APPOINTMENTS} icon={iconList}></Link>
                                :
                                isAdmin ?
                                    <>
                                        <Link route="/gestionUser" name={USERS_MANAGEMENT} icon={iconEditUser}></Link>
                                        <Link route="/gestionService" name={SERVICES_MANAGEMENT} icon={iconHandShake}></Link>
                                        <Link route="/historique" name={APPOINTMENTS_HISTORY} icon={iconHistory}></Link>
                                    </>
                                    :
                                    <>
                                        {isClientPro ?
                                            <>
                                                <Link route="/siteIntervention" name="Gestion site" icon={iconMapMarker}></Link>
                                                <Link route="/gestionClient" name="Gestion clients" icon={iconEditUser}></Link>
                                            </>

                                            :
                                            <>
                                                <Link route="/rdv" name={TAKE_APPOINTMENT} icon={iconCalendarPlus}></Link>
                                                <Link route="/account" name={USER_APPOINTMENTS} icon={iconUser}></Link>
                                            </>
                                        }
                                    </>
                            :
                            <>
                            </>
                        }
                        <Link route="/logout" name={LOGOUT} icon={iconSignOut} clickHandler={() => setShowModalLogout(true)}></Link>
                    </MDBListGroup>
                </div>
            </MDBCollapse>

            <MDBNavbar expand='lg' light bgcolor='light' className="navBarSideBar">
                <MDBContainer fluid>
                    <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
                        <MDBNavbarToggler
                            type='button'
                            aria-label='Toggle navigation'
                            onClick={toggleShow}
                        >
                            <MDBIcon icon='bars' fas/>
                        </MDBNavbarToggler>
                        <MDBNavbarBrand >
                            <img
                                className="ms-2 servitysImg"
                                src={servitysLogo}
                                height='70'
                                alt=''
                                loading='eager'
                            />
                        </MDBNavbarBrand>
                    </MDBNavbarNav>
                    <MDBNavItem className="d-flex flex-row align-items-center w-auto username">
                        {username}
                    </MDBNavItem>
                </MDBContainer>
            </MDBNavbar>


            <ModalConfirmation titre="Voulez-vous vraiment vous déconnecter ?" fonctionBtnAccept={logout} fonctionBtnRefuse={() => setShowModalLogout(false)} show={showModalLogout} body={<></>}></ModalConfirmation>
        </>

    );
}
