import {useSelector} from "react-redux";
import {Location, Navigate, Outlet, useLoaderData, useLocation} from "react-router-dom";
import {fetchAPI} from "../Components/API";
import * as React from "react";

export default function ProtectedRoute ({authObject}): React.ReactElement {

    const location: Location<any> = useLocation();

    const regexGestionClient: RegExp = new RegExp(/\/gestionClient\/.+/);
    const regexCreationUser: RegExp = new RegExp(/\/creationUser\/.+/);
    const regexModifUser: RegExp = new RegExp(/\/modifUser\/.+/);
    const regexUpdateSite: RegExp = new RegExp(/\/updateInterventionSite\/.+/);

    if (!authObject.isConnected) return <Navigate to="/login" replace={true}></Navigate>;


    if (location.pathname === "/rdvListe")
        if (!authObject.isEmploye) return <Navigate to="/" replace={true}></Navigate>;


    if (location.pathname === "/gestionUser" ||
        location.pathname === "/affect" ||
        location.pathname === "/gestionRdv" ||
        location.pathname === "/historique" ||
        regexCreationUser.test(location.pathname) ||
        regexModifUser.test(location.pathname)) if (!authObject.isAdmin) return <Navigate to="/" replace={true}></Navigate>;


    if (location.pathname === "/siteIntervention" ||
        location.pathname === "/gestionClient" ||
        regexGestionClient.test(location.pathname) ||
        location.pathname === "/creationInterventionSite" ||
        regexUpdateSite.test(location.pathname)) if (!authObject.isClientPro) return <Navigate to="/"></Navigate>;

    if (location.pathname === "/rdv" || location.pathname === "/account")
        if (authObject.isEmploye || authObject.isAdmin || authObject.isClientPro) return <Navigate to="/" replace={true}></Navigate>;


    return <Outlet/>;
}
