import {Button, Card} from "react-bootstrap";
import * as React from "react";
import {CardRdvProps} from "../types/propsType";

export default function CardRdv({rdv, isEmploye}: CardRdvProps): React.ReactElement {

    const [buttons, setButtons] = React.useState<React.ReactElement[]>([]);
    const [date, setDate] = React.useState<Date>(null);

    function launchEvent(): void {
        let event = new CustomEvent("onInterventionValidated");
        document.dispatchEvent(event);
    }


    React.useEffect(() => {

        const newDate: Date = new Date(rdv.date);

        if (new Date().toLocaleDateString() === newDate.toLocaleDateString()) {
            if (rdv.signature) {
                setButtons([<p style={{color:"green"}} key={1}>Signature déjà enregistrée</p>])
            }else if (isEmploye){
                setButtons([<Button key={1} onClick={launchEvent} variant="primary">Valider intervention</Button>]);
            }
        } else {
            setButtons([]);
        }
        setDate(newDate);

    }, [])

    return (
        <>
            {date !== null &&
                <Card>
                    <Card.Header>{rdv.typeRdv}</Card.Header>
                    <Card.Body>
                        <Card.Title>Rendez-vous du {date.toLocaleDateString("fr", {weekday: "long"})} {date.toLocaleDateString()} à {rdv.horaires[0]}h</Card.Title>
                        <Card.Text>
                            <span style={{display:"block"}}>Pour le client : {rdv.client.prenom} {rdv.client.nom}</span>
                            <span style={{display:"block"}}>Sujet : {rdv.sujet}</span>
                            <span>Lieu de l'intervention : {rdv.siteIntervention.locationSiteIntervention}</span>
                        </Card.Text>
                        {buttons}
                    </Card.Body>
                </Card>
            }
        </>
    )
}
