import {Button, Card} from "react-bootstrap";
import * as React from 'react';
import {useLoaderData} from "react-router-dom";
import {Provider, useDispatch} from "react-redux";
import {persistor, store} from "../store";
import RdvDetail from "../Components/RdvDetail";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import {fetchAPI} from "../Components/API";
import {formalizeRdvs, sortRdvs} from "../Utils/functionManager";
import {Appointment, Auth} from "../types/type";
import {DataUserRdvs} from "../types/loaderType";

let rdvChat;

export default function GestionClientSpe(): React.ReactElement {

    const dispatch = useDispatch();

    const [render, setRender] = React.useState<React.ReactElement[]>([]);

    const [isChat, setIsChat] = React.useState<boolean>(false);

    const dataLoader = useLoaderData() as DataUserRdvs;

    const rdvsData = dataLoader.rdvs;

    const idClient = dataLoader.idClient;

    const authObject: Auth = fetchAPI.authObject;


    React.useEffect(() => {
        getRdvsUser();
    }, []);

    function getRdvsUser() {
        sortRdvs(rdvsData);
        renderRdvs();
    }

    function renderRdvs() {
        let tabRender:React.ReactElement[] = [];

        for (let rdv of rdvsData) {
            const dateRdv = rdv.date;
            const dateNow = new Date();

            dateRdv.setHours(rdv.horaireFin);

            let horaire = "à -1h";

            let horaires = rdv.horaires;

            if (horaires.length > 1) {
                horaire = `de ${horaires[0]}h à ${rdv.horaireFin}h`;
            } else horaire = `à ${horaires[0]}h`;

            tabRender.push(
                <Card key={rdv.idRdv} id={rdv.idRdv.toString()} className="text-center">
                    <Card.Header>Rendez-vous
                        du {dateRdv.toLocaleDateString('fr-Fr', {weekday: 'long'})} {dateRdv.toLocaleDateString()} {horaire}</Card.Header>
                    <Card.Body>
                        <Card.Title>{rdv.typeRdv}</Card.Title>
                        <Card.Text>
                            Motif : {rdv.sujet}
                        </Card.Text>
                        {dateNow < dateRdv &&
                            <div>
                                <Button variant="primary" className="mx-3" onClick={() => {
                                    setIsChat(true);
                                    rdvChat = rdv;
                                }}>Chat</Button>
                            </div>
                        }
                    </Card.Body>
                    <Card.Footer className="text-muted">Lieu
                        : {rdv.siteIntervention.locationSiteIntervention}</Card.Footer>
                </Card>
            );
        }

        setRender(tabRender);
    }


    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <>
                        <div className="customCard">
                            {isChat ?
                                <RdvDetail rdv={rdvChat} isEmploye={authObject.isEmploye} jwt={authObject.jwt}
                                           idUser={Number(authObject.identifiant)}></RdvDetail>
                                :
                                <>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h2>Rendez-vous du client</h2>
                                    </div>
                                    {render}
                                </>
                            }
                        </div>
                    </>


                    <OnlineDetect></OnlineDetect>

                </PersistGate>
            </Provider>
        </>
    );
}
