import * as React from "react";

import Alert from "react-bootstrap/Alert";
import {MDBIcon} from "mdb-react-ui-kit";
import FlashIcon from "./FlashIcon";

export default function MessageFlash({ id, data, deleteFlash }: {
    id?: string,
    data?: any,
    deleteFlash?: (a: any, b: any) => void
}): React.ReactElement {
    const [type, content] = data;
    return (
        <Alert key={id} variant={type} onClose={deleteFlash} dismissible>
            <div className="containerFlash">
                <FlashIcon type={type}></FlashIcon>
                {content}
            </div>
        </Alert>
    );
};
