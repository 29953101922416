import {MDBBtn, MDBContainer, MDBInput} from "mdb-react-ui-kit";
import * as React from 'react';
import {fetchAPI} from './API';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {UserConnection} from "../types/type";
import {flash} from "react-universal-flash";

export default function LoginForm(): React.ReactElement {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userConnection, setUserConnection] = React.useState<UserConnection>({
        loginUser: "",
        pwdUser: ""
    });


    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    async function handlerClickLogin(): Promise<void> {
        try {
            await registerJWT();
        } catch (error) {
            flash(3000, "danger", `${error.message} | code : ${error.code}`);
        }
    }

    async function registerJWT(): Promise<void> {
        const tokenJSON: string = await fetchAPI.connexion(userConnection.loginUser, userConnection.pwdUser);
        dispatch({type: 'SET_JWT', payload: tokenJSON});
        navigate("/");
    }

    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column w-75">

            <MDBInput value={userConnection.loginUser} wrapperClass='mb-4' label='Login' id='form1' type='text'
                      onChange={(e) => setUserConnection({...userConnection, "loginUser": e.target.value})}/>


            <div className="input-group mb-3">
                <input
                    type={isVisible ? "text" : "password"}
                    className="form-control rounded"
                    placeholder="Mot de passe"
                    aria-label="Mot de passe"
                    aria-describedby="search-addon"
                    value={userConnection.pwdUser}
                    onChange={(e) => setUserConnection({...userConnection, "pwdUser": e.target.value})}
                />
                <span onClick={() => setIsVisible(!isVisible)} className="input-group-text border-0"
                      id="search-addon"><i className="fas fa-eye"></i></span>
            </div>


            <div className="d-flex justify-content-between mx-3 mb-4">
                <a href="">Mot de passe oublié ?</a>
            </div>


            <MDBBtn className="mb-4" onClick={handlerClickLogin}>Se connecter</MDBBtn>

        </MDBContainer>
    )
}
