import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Button} from "react-bootstrap";
import * as React from 'react';
import ModalConfirmation from "../ModalConfirmation";
import {LegacyRef, Ref} from "react";

/**
 * @description A component for managing payments
 * @param paymentComplete Function called once payment has been registered and completed
 * @param paymentClose
 * @param showModal
 * @param refP
 * @return {Element} An element that manages customer payments using the Stripe tool
 * @constructor
 */
export default function PaymentHandle(
    {paymentComplete, paymentClose, showModal, refP}:
        {
            paymentComplete: () => void;
            paymentClose: () => void;
            showModal: boolean;
            refP: LegacyRef<any>
        }
): React.ReactElement {

    const elements = useElements();
    const stripe = useStripe();

    const refButtonConfirm = React.useRef<HTMLButtonElement>();


    /**
     * @description Function for handling payment errors
     * @param error Event describing the error that occurred
     */
    function handleError(error: any): void {
        console.error(error);
    }

    /**
     * @description Sends payment via stripe tool and validated form
     * @param event Event describing form submission
     * @return {Promise<void>}
     */
    async function handlePayment(event: any): Promise<void> {
        event.preventDefault();

        const {error: submitError} = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
        });

        console.log(result)
        paymentComplete();
    }


    function click(): void {
        if (refButtonConfirm.current) refButtonConfirm.current.click();
    }



    return (
        <ModalConfirmation titre="Handle payment" body={
            <form ref={refP} onSubmit={handlePayment}>
                <PaymentElement />
                <Button ref={refButtonConfirm} type="submit" hidden={true}></Button>
            </form>
        } show={showModal} fonctionBtnAccept={click} fonctionBtnRefuse={paymentClose}>
        </ModalConfirmation>

    );
}
