import {Button, Table} from "react-bootstrap";
import * as React from "react";
import {Appointment} from "../types/type";
import PaginationTable from "./PaginationTable";
import {getNumberOfPages} from '../Utils/functionManager';
import {MDBFooter} from "mdb-react-ui-kit";


const MAX_ELEMENT_PAGINATION: number = 12;


export default function HistoriqueRdv({rdvs}: { rdvs: Appointment[] }): React.ReactElement {

    const [render, setRender] = React.useState<React.ReactElement[]>([]);


    const [nbPages, setNbPages] = React.useState<number>();
    const [currentPage, setCurrentPage] = React.useState<number>(1);


    React.useEffect(() => {
        setNbPages(getNumberOfPages(rdvs, MAX_ELEMENT_PAGINATION));
        refreshRender();
    }, [rdvs]);


    function launchEvent(rdv: Appointment) {
        let event = new CustomEvent("onDetailRdv", {detail: rdv});
        document.dispatchEvent(event);
    }

    function getAllRdvsFiltered(): [Appointment[], Appointment[]] {

        const rdvsPasser: Appointment[] = rdvs.filter(objet => {
            objet.date.setHours(objet.horaireFin);
            if (objet.date < new Date()) return objet;
        });

        const rdvsEnCours: Appointment[] = rdvs.filter(objet => {
            objet.date.setHours(objet.horaireFin);
            if (objet.date >= new Date()) return objet;
        });

        return [rdvsPasser, rdvsEnCours];
    }

    function refreshRender() {
        const tabRdv: React.ReactElement[] = [];

        const [rdvsPasser, rdvsEnCours] = getAllRdvsFiltered();

        for (let rdv of rdvsEnCours) {
            let horaires: string = `${rdv.horaires[0]}h à ${Number(rdv.horaires[0]) + 1}h`;

            if (rdv.horaires.length > 1) horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[rdv.horaires.length - 1])+1}h`;


            tabRdv.push(
                <tr key={rdv.idRdv}>
                    <td style={{backgroundColor: "yellow"}}>{rdv.idRdv}</td>
                    <td style={{backgroundColor: "yellow"}}>{rdv.service.name}</td>
                    <td style={{backgroundColor: "yellow"}}>{`${rdv.service.price} €`}</td>
                    <td style={{backgroundColor: "yellow"}}>{rdv.date.toLocaleDateString()}</td>
                    <td style={{backgroundColor: "yellow"}}>{horaires}</td>
                    <td style={{backgroundColor: "yellow"}}>{rdv.client.nom}</td>
                    <td style={{backgroundColor: "yellow"}}>{rdv.client.prenom}</td>
                    <td style={{backgroundColor: "yellow"}}><Button onClick={() => launchEvent(rdv)} variant="primary">Voir détail</Button></td>
                </tr>
            );
        }

        for (let rdv of rdvsPasser) {
            let horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[0])+1}h`;

            if (rdv.horaires.length !== 1) horaires = `${rdv.horaires[0]}h à ${Number(rdv.horaires[rdv.horaires.length - 1])+1}h`;

            let backGroundColor = "#ff9b9b";

            if (rdv.signature) backGroundColor = "#99CC99";

            tabRdv.push(
                <tr key={rdv.idRdv}>
                    <td style={{backgroundColor: backGroundColor}}>{rdv.idRdv}</td>
                    <td style={{backgroundColor: backGroundColor}}>{rdv.service.name}</td>
                    <td style={{backgroundColor: backGroundColor}}>{`${rdv.service.price} €`}</td>
                    <td style={{backgroundColor: backGroundColor}}>{rdv.date.toLocaleDateString()}</td>
                    <td style={{backgroundColor: backGroundColor}}>{horaires}</td>
                    <td style={{backgroundColor: backGroundColor}}>{rdv.client.nom}</td>
                    <td style={{backgroundColor: backGroundColor}}>{rdv.client.prenom}</td>
                    <td style={{backgroundColor: backGroundColor}}><Button onClick={() => launchEvent(rdv)} variant="primary">Voir détail</Button></td>
                </tr>
            );
        }

        setRender(tabRdv);

    }

    return (
        <>
            <Table responsive={true}>
                <thead className="table-dark">
                <tr>
                    <th colSpan={3}>Rdv</th>
                    <th colSpan={2}>Temps</th>
                    <th colSpan={2}>Client</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <th>Id</th>
                    <th>Prestation</th>
                    <th>Prix</th>
                    <th>Date</th>
                    <th>Horaire</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                </tr>
                </thead>
                <tbody style={{verticalAlign: "middle"}}>
                {render.slice((currentPage-1) * MAX_ELEMENT_PAGINATION, currentPage * MAX_ELEMENT_PAGINATION)}
                </tbody>
            </Table>

           <MDBFooter>
               <PaginationTable style={{position:"relative", bottom: "0", left: "50%"}} nbPages={nbPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
           </MDBFooter>


        </>
    )
}

