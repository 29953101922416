import {Adress, Appointment, ChatMessage, ErrorAPI, InterventionSite, Service, User} from "../types/type";
import {
    formalizeAdressFromApi,
    formalizeChatMessages, formalizeInterventionSites,
    formalizeOneRdv,
    formalizeOneService, formalizeOneUser,
    formalizeRdvs,
    formalizeServices,
    formalizeUsers
} from "../Utils/functionManager";
import ApiException from "../Exceptions/ApiException";

const entryPointAPI: string = "https://pwa.securitys.fr/pwa/public/api";


export const fetchAPI = {

    authObject: {
        jwt: undefined,
        isAdmin: false,
        isEmploye: false,
        isClientPro: false,
        identifiant: "",
        username: "",
        isConnected: false
    },

    async connexion(login: string, motDePasse: string): Promise<string> {
        let responseJson: any;

        try {
            const response = await fetch(`${entryPointAPI}/auth`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    login: login,
                    mdp: motDePasse
                }),
            });

            responseJson = await response.json();
        } catch (e) {
            throw new ApiException("Erreur lors de la connexion", e.status);
        }

        if (responseJson.code) throw new ApiException("Informations de connexion invalides !", responseJson.code);
        else return responseJson.token;
    },

    async creerUser(nom: string, prenom: string, login: string, mdp: string, jwt: string, role: string[] = null): Promise<any> {
        let bodyObject: { prenom: string, nom: string, login: string, plainPassword: string, roles?: string[] } = {
            prenom: prenom,
            nom: nom,
            login: login,
            plainPassword: mdp,
            roles: role !== null ? role : undefined
        }

        /*
        bodyObject.prenom = prenom;
        bodyObject.nom = nom;
        bodyObject.login = login;
        bodyObject.plainPassword = mdp;

        if (role !== null) bodyObject.roles = role;

         */

        try {
            let response = await fetch(`${entryPointAPI}/utilisateurs`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: JSON.stringify(bodyObject)
            });
            return await response.json();

        } catch (e) {
            console.log(e);
            return e;
        }
    },

    deleteUser(idUser, jwt) {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response
        }).catch(e => {
            console.log(e);
            return e;
        });
    },

    async getUser(idUser: number, jwt: string): Promise<User> {
        try {
            const response = await fetch(`${entryPointAPI}/utilisateurs/${idUser}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            const responseJson = await response.json();
            return formalizeOneUser(responseJson);
        } catch (e) {
            console.log(e);
            return e;
        }
    },
    getRdvForWeek(day: string, jwt): Promise<Appointment[] | ErrorAPI> {
        return fetch(`${entryPointAPI}/rdvs?date=${encodeURI(day)}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response.json()
                .then(responseJson => {
                    if (responseJson.code === 401) return responseJson;
                    else return formalizeRdvs(responseJson["hydra:member"]);
                });
        }).catch(e => {
            console.log(e);
            return {
                code: e.code,
                message: "Erreur lors de la récupération des rendez-vous pour la semaine"
            };
        });
    },
    takeRdv(date: string, time, subject, typeRdv, idSite, idService, jwt) {
        return fetch(`${entryPointAPI}/rdvs`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({
                sujet: subject,
                date: date,
                typeRdv: typeRdv,
                horaires: time,
                idSiteIntervention: idSite,
                idService: idService
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getRdvsUser(idUser, jwt): Promise<Appointment[]> {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}/rdvs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json())
            .then(responseJSON => {
                if (responseJSON.code === 401) return responseJSON;
                else return formalizeRdvs(responseJSON["hydra:member"]);
            }).catch(e => {
                console.log(e);
                return e;
            });
    },
    deleteRdv(idRdv, jwt) {
        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAllMessagesByRdv(idRdv: number, jwt: string): Promise<ChatMessage[]> {
        return fetch(`${entryPointAPI}/rdvs/${idRdv}/messages`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            if (responseJSON.code === 401) return responseJSON;
            else return formalizeChatMessages(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    createMessage(message, idRdv, date: string, horaire, jwt, file = null) {

        let formData = new FormData();

        formData.append("message", message);
        formData.append("idRdv", idRdv);
        formData.append("date", date);
        formData.append("horaire", horaire);

        if (file !== null) formData.append("file", file);


        return fetch(`${entryPointAPI}/messages`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt
            },
            body: formData
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        });
    },
    makeSignature(nom, idRdv, date: string, time, file, latitude: number, longitude: number, jwt) {

        let formData = new FormData();

        formData.append("nomSignature", nom);
        formData.append("idRdv", idRdv);
        formData.append("dateSignature", date);
        formData.append("time", time);
        formData.append("file", file);
        formData.append("latitudePost", latitude.toString());
        formData.append("longitudePost", longitude.toString());


        return fetch(`${entryPointAPI}/signatures`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt
            },
            body: formData
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        });
    },
    updateRdv(idRdv, idEmploye, date, horaires, jwt, idSiteIntervention:string = null, idService: string = null) {
        let objetBody: {idEmploye?: number, date?: Date, horaires?: string[], idSiteIntervention?: string, idService?: string} = {
            idEmploye: idEmploye !== null ? idEmploye : undefined,
            date: date !== null ? date : undefined,
            horaires: horaires !== null ? horaires : undefined,
            idSiteIntervention: idSiteIntervention !== null ? idSiteIntervention : undefined,
            idService: idService !== null ? idService : undefined
        };

        /*
        if (idEmploye !== null) objetBody.idEmploye = idEmploye;
        if (date !== null) objetBody.date = date;
        if (horaires !== null) objetBody.horaires = horaires;
        if (idSiteIntervention !== null) objetBody.idSiteIntervention = idSiteIntervention;

         */

        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify(objetBody)
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    updateUser(idUser, nom, prenom, login, mdp, idChef, jwt, roles) {
        let objetBody: {nom?: string, prenom?:string, login?: string, mdp?:string, idChef?: number, roles: string[]} = {
            nom: nom !== null ? nom : undefined,
            prenom: prenom !== null ? prenom : undefined,
            login: login !== null ? login : undefined,
            mdp: mdp !== null ? mdp : undefined,
            idChef: idChef !== null ? idChef : undefined,
            roles: roles !== null ? roles : undefined
        };

        /*
        if (nom !== null) objetBody.nom = nom;
        if (prenom !== null) objetBody.prenom = prenom;
        if (login !== null) objetBody.login = login;
        if (mdp !== null) objetBody.plainPassword = mdp;
        if (idChef !== null) objetBody.idChef = idChef;

         */

        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify(objetBody)
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    updateInterventionSite(idSite: number, nom: string, location: string, latitude: number, longitude: number, jwt: string) {
        return fetch(`${entryPointAPI}/site_interventions/${idSite}`, {
            method: "PATCH",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/merge-patch+json'
            },
            body: JSON.stringify({
                nomSiteIntervention: nom,
                locationSiteIntervention: location,
                latitude: latitude,
                longitude: longitude
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON
        }).catch(e => {
            console.log(e);
            return e;
        });

    },
    getAllRdvs(jwt): Promise<Appointment[]> {
        return fetch(`${entryPointAPI}/rdvs/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeRdvs(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllEmployes(jwt, idAdmin): Promise<User[]> {
        const users: User[] = await this.getAllUser(jwt, idAdmin);

        return users.filter(objet => objet.id !== idAdmin && objet.roles.includes("ROLE_EMPLOYE"));
    },
    getAllClientsPro(jwt) {
        return fetch(`${entryPointAPI}/utilisateurs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(respone => respone.json()).then(responseJSON => {
            return responseJSON["hydra:member"].filter(objet => objet.roles.includes("ROLE_CLIENTPRO"));
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllClient(jwt, idAdmin): Promise<User[]> {
        const users: User[] = await this.getAllUser(jwt, idAdmin);

        return users.filter(objet => objet.id !== idAdmin && !objet.roles.includes("ROLE_EMPLOYE"));
    },
    getAllUser(jwt, idAdmin) {
        return fetch(`${entryPointAPI}/utilisateurs`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(respone => respone.json()).then(responseJSON => {
            return formalizeUsers(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getRdv(idRdv, jwt): Promise<Appointment> {
        return fetch(`${entryPointAPI}/rdvs/${encodeURI(idRdv)}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeOneRdv(responseJSON);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAdressByCoordinates(latitude, longitude) {
        return fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=fr`, {
            method: "GET"
        }).then(response => response.json())
            .then(responseJSON => {
                return responseJSON;
            }).catch(e => {
                console.log(e);
                return e;
            });
    },
    getAllSiteInterventionByUserPro(idUser, jwt): Promise<InterventionSite[]> {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idUser)}/site_interventions`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return formalizeInterventionSites(responseJSON["hydra:member"]);
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    creerSiteIntervention(nom, location, latitude, longitude, jwt) {
        return fetch(`${entryPointAPI}/site_interventions`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nomSiteIntervention: nom,
                locationSiteIntervention: location,
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude)
            })
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    deleteSiteIntervention(idSite, jwt) {
        return fetch(`${entryPointAPI}/site_interventions/${encodeURI(idSite)}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => {
            return response
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    getAllClientByChef(idChef, jwt) {
        return fetch(`${entryPointAPI}/utilisateurs/${encodeURI(idChef)}/clients`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            if (responseJSON.code) return responseJSON;
            else return formalizeUsers(responseJSON["hydra:member"])
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    createPaymentIntent(amount, jwt) {
        return fetch("https://pwa.securitys.fr/pwa/public/stripe/create-charge", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({
                amount: amount
            })
        }).then(response => response.json()).then(responseJSON => {return responseJSON});
    },
    getInterventionSiteById(idSite, jwt) {
        return fetch(`${entryPointAPI}/site_interventions/${idSite}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        }).then(response => response.json()).then(responseJSON => {
            return responseJSON;
        }).catch(e => {
            console.log(e);
            return e;
        });
    },
    async getAllServices(jwt): Promise<Service[]> {
        try {

            let servicesArray: Service[] = [];

            let response = await fetch(`${entryPointAPI}/services?status=false`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            let responseJSON: any = await response.json();
            if (responseJSON.code) return responseJSON;
            else servicesArray = formalizeServices(responseJSON["hydra:member"]);

            let response2 = await fetch(`${entryPointAPI}/services?status=true`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });

            let responseJSON2: any = await response2.json();
            if (responseJSON2.code) return responseJSON2;
            else servicesArray = [...servicesArray, ...formalizeServices(responseJSON2["hydra:member"])];

            return servicesArray;

        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getServicesForClients(jwt): Promise<Service[]> {
        try {

            let response = await fetch(`${entryPointAPI}/services?status=true`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
            let responseJSON: any = await response.json();
            if (responseJSON.code) return responseJSON;
            else return formalizeServices(responseJSON["hydra:member"]);

        } catch (e) {
            console.error(e);
            return e;
        }
    },

    getAdress(adress: string): Promise<Adress[]> {
        return fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURI(adress)}`).then(response => response.json()).then(responseJSON => {
            return formalizeAdressFromApi(responseJSON["features"]);
        }).catch(e => {
            console.error(e);
            return e;
        });
    },
    async deleteService(idService: number, jwt: string): Promise<any> {
        try {
            return await fetch(`${entryPointAPI}/services/${idService}`, {
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + jwt
                }
            });
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async updateService(idService: number, name: string, description: string, duration: number, price: number, status: boolean, jwt: string): Promise<any> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}`, {
                method: "PATCH",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/merge-patch+json'
                },
                body: JSON.stringify({
                    name: name,
                    duration: duration,
                    price: price,
                    description: description,
                    status: status
                })
            });
            return await response.json();
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async createService(name: string, description: string, duration: number, price: number, status: boolean, jwt: string): Promise<any> {
        try {
            const response = await fetch(`${entryPointAPI}/services`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    duration: duration,
                    price: price,
                    description: description,
                    status: status
                })
            });
            return await response.json();
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getRdvsUpForService(idService: number, jwt: string): Promise<Array<object>> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}/rdvs?rdvUp=true`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                },
            });
            const responseJSON = await response.json();
            return responseJSON["hydra:member"];
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async isServiceSafeToEdit(idService: number, jwt: string): Promise<boolean> {
        try {
            const rdvs: object[] = await this.getRdvsUpForService(idService, jwt);
            return rdvs.length === 0;
        } catch (e) {
            console.error(e);
            return e;
        }
    },
    async getService(idService: number, jwt: string): Promise<Service> {
        try {
            const response = await fetch(`${entryPointAPI}/services/${idService}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                }
            });
            const responseJSON = await response.json();
            return formalizeOneService(responseJSON);
        } catch (e) {
            console.error(e);
            return e;
        }
    }

}


