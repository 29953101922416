import {MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import qrCode from "../img/qrCode.png";
import ButtonInstallApp from "../Components/ButtonInstallApp";
import LoginForm from "../Components/LoginForm";
import * as React from "react";
import {useMediaQuery} from "react-responsive";

export default function Login (): React.ReactElement {

    const isMobile: boolean = useMediaQuery({query: `(max-width: 760px)`});
    const isStandalone: boolean = window.matchMedia('(display-mode: standalone)').matches;

    const displayForm: boolean = isStandalone || !isMobile;


    return (
        <>
            <div className="customCard">
                <MDBRow>
                    <MDBCol className="ms-2 qr-code-card">
                        <MDBRow className="justify-content-center">

                            <MDBCard className="align-items-center">
                                <MDBCardImage src={qrCode} alt="qrCode" className="qrCode"></MDBCardImage>
                                <MDBCardBody>
                                    <p>SCANNER LE QR CODE POUR ETRE RAMENER SUR LE SITE</p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol className="ms-2 align-items-center conteneurBlock">
                        {!isStandalone &&
                            <MDBContainer className="p-3 my-5 flex-column w-75 conteneurInstallBouton">
                                <ButtonInstallApp></ButtonInstallApp>
                            </MDBContainer>
                        }

                        {displayForm &&
                            <LoginForm></LoginForm>
                        }

                    </MDBCol>

                </MDBRow>
            </div>
        </>
    );
}
