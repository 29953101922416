import {Auth, DataActionService, Service, User} from "../../../types/type";
import {fetchAPI} from "../../../Components/API";
import {DataActionsUser} from "../../../types/actionType";
import {redirect, Navigate} from "react-router-dom";
import {flash} from "react-universal-flash";

export default async function actionsUser ({request}): Promise<Response> {
    const formData = await request.formData();
    const authObject: Auth = fetchAPI.authObject;

    const data: DataActionsUser = {
        user: {
            id: formData.get("id"),
            firstName: formData.get("firstName"),
            lastName: formData.get("lastName"),
            idBoss: formData.get("boss"),
            isPro: formData.get("isPro"),
            isEmploye: formData.get("isEmploye"),
            login: formData.get("login"),
            pwd: formData.get("pwd"),
            pwdConfirm: formData.get("pwdConfirm")
        },
        typeUser: formData.get("objectType"),
        authObject: authObject
    }

    console.log(data)

    let success: boolean = false;

    switch (formData.get("action")) {
        case "update":
            const initialUser: User = await fetchAPI.getUser(data.user.id, data.authObject.jwt);
            success = await verifUpdateUser(data, initialUser);
            break;
        case "create":
            success = await verifCreateUser(data);
            break;
    }


    return success ? redirect("/gestionUser") : null;
}

async function verifUpdateUser(data: DataActionsUser, initialUser: User): Promise<boolean> {

    let success = false;

    let canUpdate = true;

    if (data.user.lastName.length === 0) {
        flash(5000, "warning", "Le nom doit être renseigné.");
        canUpdate = false;
    }
    else if (data.user.firstName.length === 0) {
        flash(5000, "warning", "Le prénom doit être renseigné.");
        canUpdate = false;
    }
    else if (data.user.login.length === 0) {
        flash(5000, "warning", "Le login doit être renseigné.");
        canUpdate = false;
    }
    else if (data.user.pwd.length !== 0) {
        if (data.user.pwd.length === 0 || data.user.pwdConfirm.length === 0) {
            flash(5000, "warning", "Un mot de passe doit être renseigné.");
            canUpdate = false;
        }
        else if (data.user.pwd !== data.user.pwdConfirm) {
            flash(5000, "warning", "Les mots de passes doivent être identiques.");
            canUpdate = false;
        }
    }

    if (canUpdate) {
        let idBoss = data.user.idBoss;
        if (idBoss !== null)
            if (idBoss.toString() === "undefined") idBoss = null

        let roles = null;

        if (data.user.isPro) roles = ["ROLE_CLIENTPRO"]

        const response = await fetchAPI.updateUser(data.user.id, data.user.lastName, data.user.firstName, data.user.login, data.user.pwd === "" ? null : data.user.pwd, idBoss, data.authObject.jwt,  roles);
        console.log(response);
        if (response.code) {
            flash(5000, "danger", "Une erreur est survenue (cf. voir la console pour plus d'information)");
        } else {
            flash(5000, "success", "L'utilisateur à bien été modifié.");
            success = true;
        }
    }
    return success;
}


async function verifCreateUser(data: DataActionsUser): Promise<boolean> {
    let success = false;

    if (data.user.lastName.length === 0) flash(5000, "warning", "Le nom doit être renseigné.");
    else if (data.user.firstName.length === 0) flash(5000, "warning", "Le prénom doit être renseigné.");
    else if (data.user.login.length === 0) flash(5000, "warning", "Le login doit être renseigné.");
    else if (data.user.pwd.length === 0 || data.user.pwdConfirm.length === 0) flash(5000, "warning", "Un mot de passe doit être renseigné.");
    else if (data.user.pwd !== data.user.pwdConfirm) flash(5000, "warning", "Les mots de passes doivent être identiques.");
    else {
        const response = await fetchAPI.creerUser(data.user.lastName, data.user.firstName, data.user.login, data.user.pwd, data.authObject.jwt, data.typeUser === "employe" ? ["ROLE_EMPLOYE"] : []);
        console.log(response);
        if (response.code) {
            flash(5000, "danger", "Une erreur est survenue (cf. voir la console pour plus d'information)");
        } else if (response.status === 422) {
            flash(5000, "danger", response.detail);
        } else {
            flash(5000, "success", "L'utilisateur à bien été créer.");
            success = true;
        }
    }

    return success;
}
