import {Adress, Appointment, ChatMessage, InterventionSite, Service, Signature, User, UserGestion} from "../types/type";

/**
 * Changes the information contained in objects representing appointments
 * @param rdvs Array containing appointment information retrieved by the API request
 * @return array
 */
function formalizeRdvs(rdvs: any[]): Appointment[] {
    return rdvs.map(objet => formalizeOneRdv(objet));
}

function formalizeServices(services: any[]): Service[] {
    return services.map(objet => formalizeOneService(objet));
}

function formalizeUsers(users: any[]): User[] {
    return users.map(objet => formalizeOneUser(objet));
}

function formalizeChatMessages(chatMessages: any[]): ChatMessage[] {
    return chatMessages.map(object => formalizeOneChatMessage(object));
}

function formalizeInterventionSites(interventionSites: any[]): InterventionSite[] {
    return interventionSites.map(object => formalizeOneInterventionSite(object))
}


function formalizeOneInterventionSite(object: any): InterventionSite {
    return {
        id: object.id,
        locationSiteIntervention: object.locationSiteIntervention,
        nomSiteIntervention: object.nomSiteIntervention,
        longitude: object.longitude,
        latitude: object.latitude
    }
}


function formalizeOneService(objet: any): Service {
    const durationHour = Math.floor(objet.duration);

    return {
        idService: objet.id,
        name: objet.name,
        duration: objet.duration,
        description: objet.description,
        price: objet.price,
        status: objet.status,
        durationString: `${durationHour}h${60*(objet.duration-durationHour)}`
    }
}

function formalizeOneRdv(objet: any): Appointment {
    return {
        idRdv: objet.id,
        sujet: objet.sujet,
        typeRdv: objet.typeRdv,
        date: new Date(objet.date),
        horaires: objet.horaires,
        client: objet.client,
        messages: formalizeChatMessages(objet.messages),
        horaireDepart: objet.horaires[0],
        horaireFin: Number(objet.horaires[objet.horaires.length - 1]) + 1,
        siteIntervention: objet.siteIntervention,
        signature: formalizeOneSignature(objet.signature),
        chef: objet.client.chef,
        service: formalizeOneService(objet.service),
        employe: objet.employe
    }
}


function formalizeOneChatMessage(object: any): ChatMessage {
    return {
        id: object.id,
        appointment: {
            id: object.rdv.id
        },
        message: object.message,
        horaire: object.horaire,
        date: new Date(object.date),
        imagePath: object.contentUrl,
        client: object.client
    }
}

function formalizeOneSignature(objet:any): Signature {
    if (!objet) return objet;
    if (!objet.time) return objet;
    const splitedTime = objet.time.split(":");
    return {
        timeString: objet.time,
        hours: Number(splitedTime[0]),
        minute: Number(splitedTime[1]),
        dateSignature: new Date(objet.dateSignature),
        latitude: objet.latitude,
        longitude: objet.longitude,
        nameSignature: objet.nomSignature,
        imagePath: objet.contentUrl
    }
}

function formalizeOneUser(objet: any): User {
    return {
        id: objet.id,
        firstName: objet.prenom,
        lastName: objet.nom,
        login: objet.login,
        roles: objet.roles,
        boss: objet.chef,
        isPro: objet.roles.includes("ROLE_CLIENTPRO"),
        isEmploye: objet.roles.includes("ROLE_EMPLOYE")
    }
}

function formalizeAdressFromApi(adress: any[]): Adress[] {
    return adress.map((objet) => ({
        cityName: objet.properties.label,
        coordinates: objet.geometry.coordinates
    }));
}

/**
 * Sorts a table of appointments from most recent to oldest
 * @param rdvs Array containing appointment information from the formalizedRdvs function
 */
function sortRdvs(rdvs: Appointment[]) {
    rdvs.sort(function (a, b) {
        return b.date.getTime() - a.date.getTime();
    });
}

function filterRdvPostToday(rdvs: Appointment[]) {
    return rdvs.filter(objet => {
        objet.date.setHours(objet.horaireFin);
        if (objet.date >= new Date()) return objet;
    });
}

function filterRdvPastToday(rdvs: Appointment[]) {
    return rdvs.filter(objet => {
        objet.date.setHours(objet.horaireFin);
        if (objet.date <= new Date()) return objet;
    });
}

function getNumberOfPages(arrayOfObject: any[], maxNumberOfObjectByPage: number) {
    const restOfDivision = arrayOfObject.length % maxNumberOfObjectByPage;

    const numberPagination = Math.trunc(arrayOfObject.length / maxNumberOfObjectByPage);

    return restOfDivision !== 0 ? numberPagination+1 : numberPagination;
}

function compareServices(a: Service, b: Service): boolean {
    return a.duration === b.duration &&
        a.price === b.price &&
        a.description === b.description &&
        a.name === b.name;
}

function getEmptyService(): Service {
    return {
        idService: -1,
        name: "",
        duration: 0,
        durationString: "",
        description: "",
        price: 0,
        status: false
    }
}

function getEmptyUserGestion(): UserGestion {
    return {
        id: -1,
        lastName: "",
        firstName: "",
        boss: undefined,
        isPro: false,
        isEmploye: false,
        login: "",
        pwd: "",
        pwdConfirm: ""
    }
}


export {
    getEmptyUserGestion,
    formalizeUsers,
    compareServices,
    formalizeRdvs,
    sortRdvs,
    filterRdvPostToday,
    filterRdvPastToday,
    formalizeServices,
    formalizeAdressFromApi,
    getNumberOfPages,
    formalizeOneRdv,
    formalizeOneService,
    getEmptyService,
    formalizeChatMessages,
    formalizeInterventionSites,
    formalizeOneUser
}
