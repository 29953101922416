import * as React from 'react';
import {Provider, useSelector} from "react-redux";
import {persistor, store} from "../store";
import NavigationSideBar from "../Components/NavBar/NavigationSideBar";
import OnlineDetect from "../OnlineDetection/OnlineDetect";
import {PersistGate} from "redux-persist/integration/react";
import Planning from "../Components/RDV/Planning";
import ChoixRDV from "../Components/RDV/ChoixRDV";
import {fetchAPI} from "../Components/API";
import {Elements, PaymentElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {Button} from "react-bootstrap";
import {useLoaderData} from "react-router-dom";
import {Auth, Service} from "../types/type";
import {InterventionSite} from "../types/type";
import {AppointmentChoice} from "../types/type";
import {DataServices} from "../types/loaderType";


const stripePromise = await loadStripe('pk_test_51OWy1DGraefg4D4JxKFOdVm0uXGx0yP6jgynndZJ1eexWYt42W9ExVLpP75iJkiyMyYuehAk8UYJKvg7oY9n3fCN00eu7F5rd0');

export default function RDV(): React.ReactElement {

    const [choixTypeRdv, setChoixTypeRdv] = React.useState<string>("");
    const [idSiteIntervention, setIdSiteIntervention] = React.useState<number>(0);
    const [durationRdv, setDurationRdv] = React.useState<number>(0);
    const [idService, setIdService] = React.useState<number>(0);
    const [clientSecret, setClientSecret] = React.useState<string>();

    const authObject: Auth = fetchAPI.authObject;

    const dataLoader: DataServices = useLoaderData() as DataServices;

    const services: Service[] = dataLoader.services;
    const sites: InterventionSite[] = dataLoader.sites;

    const option = React.useMemo(() => ({
        clientSecret
    }), [clientSecret]);


    React.useEffect(() => {

        //Ammount à l'air d'être en centimes
        //FAIRE DEUX PAGES DE LA MODAL QUI S'AFFICHE AVEC PREMIER TRUC DESCRIPTION ET DEUXIEME TRUC PAIEMENT AVEC BOUTON NEXT ET PRECEDENT QUE SI ON NE MODIF PAS LE RENDEZ-VOUS
        async function onChoixEffectuer(event): Promise<void> {
            let choixRdv: AppointmentChoice = event.detail;

            const response = await fetchAPI.createPaymentIntent(5000, fetchAPI.authObject.jwt);

            setClientSecret(response.client_secret);

            setChoixTypeRdv(choixRdv.typeRdv);
            setDurationRdv(choixRdv.duration);
            setIdSiteIntervention(choixRdv.idSite);
            setIdService(choixRdv.idService);
        }

        function onRdvConfirm() {
            setChoixTypeRdv("");
            setDurationRdv(0);
            setIdSiteIntervention(0);
            setIdService(0);
        }

        document.addEventListener("onChoixEffectuer", onChoixEffectuer);
        document.addEventListener("onRdvConfirm", onRdvConfirm);

        return () => {
            document.removeEventListener("onChoixEffectuer", onChoixEffectuer);
            document.removeEventListener("onRdvConfirm", onRdvConfirm);
        }
    }, []);




    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>

                    <div className="customCard">
                        {choixTypeRdv !== "" ?
                            clientSecret &&
                            <Elements stripe={stripePromise} options={option}>
                                <Planning jwt={authObject.jwt} typeRdv={choixTypeRdv} durationRdv={durationRdv} idSite={idSiteIntervention} idService={idService}></Planning>
                            </Elements>
                            :
                            <ChoixRDV sites={sites} services={services} jwt={authObject.jwt} idUser={Number(authObject.identifiant)}></ChoixRDV>
                        }
                    </div>

                    <OnlineDetect></OnlineDetect>
                </PersistGate>
            </Provider>

        </>
    );
}
