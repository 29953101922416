import {fetchAPI} from "../Components/API";
import {sortRdvs} from "../Utils/functionManager";
import {
    Appointment,
    Auth,
    InterventionSite,
    LoaderDataGestionInterventionSite,
    LoaderDataUpdateAppointment,
    Service,
    User
} from "../types/type";
import {DataAllUser} from "../types/loaderType";
import {DataUserRdvs} from "../types/loaderType";
import {DataUserUpdate} from "../types/loaderType";
import {DataUserRdvsAndServices} from "../types/loaderType";
import {DataServices} from "../types/loaderType";

export async function loaderUserRdvs(): Promise<DataUserRdvsAndServices> {
    const authObject: Auth = fetchAPI.authObject;

    const rdvs: Appointment[] = await fetchAPI.getRdvsUser(authObject.identifiant, authObject.jwt);

    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    sortRdvs(rdvs);

    return {
        rdvs: rdvs,
        services: services
    };
}

export async function loaderAnotherUserRdvs({params}): Promise<DataUserRdvs> {
    const authObject: Auth = fetchAPI.authObject;
    const response: Appointment[] = await fetchAPI.getRdvsUser(params.idClient, authObject.jwt);

    return {
        rdvs: response,
        idClient: params.idClient
    };
}

export async function loaderServices(): Promise<DataServices> {
    const authObject: Auth = fetchAPI.authObject;

    const user: User = await fetchAPI.getUser(Number(authObject.identifiant), authObject.jwt);

    let interventionsSite: InterventionSite[] = [];

    if (user.boss) {
        interventionsSite = await fetchAPI.getAllSiteInterventionByUserPro(user.boss.id, authObject.jwt);
    }

    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    return {
        services: services,
        sites: interventionsSite
    }
}


export async function loaderInterventionSiteById({params}): Promise<InterventionSite> {
    const authObject = fetchAPI.authObject;
    return await fetchAPI.getInterventionSiteById(params.idSite, authObject.jwt);
}

export async function loaderInterventionSiteUpdate({params}): Promise<LoaderDataGestionInterventionSite> {
    const interventionSite: InterventionSite = await loaderInterventionSiteById({params: params});

    return {
        interventionSite: interventionSite,
        idSite: params.idSite,
        action: "update"
    }
}

export async function loaderInterventionSiteCreate(): Promise<LoaderDataGestionInterventionSite> {
    return {
        action: "create"
    }
}

export async function loaderUserByIdUpdate({params}): Promise<DataUserUpdate> {
    const authObject: Auth = fetchAPI.authObject;
    const usersPro = await fetchAPI.getAllClientsPro(authObject.jwt);
    const user: User = await fetchAPI.getUser(params.idUser, authObject.jwt);

    return {
        user: user,
        usersPro: usersPro,
        idUser: params.idUser,
        typeUser: params.typeUser
    }
}

export async function loaderAllInterventionSiteByIdClientPro(): Promise<InterventionSite[]> {
    const authObject: Auth = fetchAPI.authObject;
    return await fetchAPI.getAllSiteInterventionByUserPro(authObject.identifiant, authObject.jwt);
}

export async function loaderAllRdvs(): Promise<Appointment[]> {
    const authObject: Auth = fetchAPI.authObject;

    const rdvs: Appointment[] = await fetchAPI.getAllRdvs(authObject.jwt);

    sortRdvs(rdvs);

    return rdvs;
}

export async function loaderAllRdvsAffectation(): Promise<Appointment[]> {
    let rdvs: Appointment[] = await loaderAllRdvs();
    return rdvs.filter(objet => !objet.employe);
}

export async function loaderRdvById({params}): Promise<Appointment> {
    const authObject: Auth = fetchAPI.authObject;

    return await fetchAPI.getRdv(params.idRdv, authObject.jwt);
}

export async function loaderUpdateAppointment({params}): Promise<LoaderDataUpdateAppointment> {
    const authObject: Auth = fetchAPI.authObject;
    const rdvModif: Appointment = await loaderRdvById({params: params});
    const interventionSites: InterventionSite[] = await fetchAPI.getAllSiteInterventionByUserPro(rdvModif.chef.id, authObject.jwt);
    const services: Service[] = await fetchAPI.getServicesForClients(authObject.jwt);

    return {
        rdvModif: rdvModif,
        interventionSites: interventionSites,
        services: services
    }
}

export async function loaderAllServices(): Promise<Service[]> {
    const authObject: Auth = fetchAPI.authObject;

    return await fetchAPI.getAllServices(authObject.jwt);
}

export async function loaderAllUsers(): Promise<DataAllUser> {
    const authObject: Auth = fetchAPI.authObject;

    const clients: User[] = await fetchAPI.getAllClient(authObject.jwt, authObject.identifiant);

    const employes: User[] = await fetchAPI.getAllEmployes(authObject.jwt, authObject.identifiant);

    return {
        clients,
        employes
    }

}
