import * as React from 'react';
import TableUser from "./TableUser";
import {ButtonTable} from "../types/type";
import {ChoixEmployeAffectationProps} from "../types/propsType";


export default function ChoixEmployeAffectation({jwt, idAdmin, rdv}: ChoixEmployeAffectationProps): React.ReactElement {

    const buttons: ButtonTable[] = [
        {
            name: "Affecter",
            eventName: "onChoixEmploye",
            variant: "primary"
        }
    ];

    React.useEffect(() => {
        function launchEvent(event) {
            let idEmploye = event.detail;
            const clickRdv = new CustomEvent("onChoixAffecterEmploye", {
                detail: {
                    idEmploye: idEmploye,
                    rdv: rdv
                }
            });
            document.dispatchEvent(clickRdv);
        }

        document.addEventListener("onChoixEmploye", launchEvent);

        return () => {
            document.removeEventListener("onChoixEmploye", launchEvent);
        }
    }, []);



    return (
        <TableUser jwt={jwt} idAdmin={idAdmin} buttons={buttons} wantEmploye={true}></TableUser>
    )
}

