import {Table} from "react-bootstrap";
import * as React from 'react';
import {fetchAPI} from "./API";
import ButtonEmploye from "./ButtonEmploye";
import {flash} from "react-universal-flash";
import {User} from "../types/type";

export default function TableUserForClientPro ({idClientPro, jwt}): React.ReactElement {

    const [render, setRender] = React.useState([]);


    React.useEffect(() => {
        refreshVisual().then();
    }, []);

    async function refreshVisual(): Promise<void> {
        let tabRenderTemp: React.ReactElement[] = [];

        let clients: User[] = await getAllClients();

        for (let client of clients) {
            tabRenderTemp.push(createRowForClient(client));
        }

        setRender(tabRenderTemp);
    }

    function createRowForClient(client: User): React.ReactElement {
        return (
            <tr key={client.id}>
                <td>{client.id}</td>
                <td>{client.lastName}</td>
                <td>{client.firstName}</td>
                <td>
                    <ButtonEmploye variant="primary" buttonName="Voir rendez-vous" eventName="onChoixClient" dataOnClick={client}></ButtonEmploye>
                </td>
            </tr>
        )
    }
    async function getAllClients(): Promise<User[]> {
        return await fetchAPI.getAllClientByChef(idClientPro, jwt);
    }

    return (
        <>
            <Table responsive={true}>
                <thead className="table-dark">
                <tr>
                    <th>Id</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody style={{verticalAlign: "middle"}}>
                {render}
                </tbody>
            </Table>
        </>
    );
}
