import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {Badge, Button} from "react-bootstrap";
import * as React from "react";
import {ReactElement} from "react";
import {AppointmentChoice, InterventionSite, Service} from "../../types/type";
import {deleteAllFlashes, flash} from "react-universal-flash";
import InterventionSiteChoice from "./InterventionSiteChoice";

/**
 * @description A component giving users a choice of Service and location for their appointments
 * @param jwt The jwt retrieved when the user logs in
 * @param idUser User ID of currently logged-in user
 * @param services
 * @param sites
 * @return {JSX.Element}
 * @constructor
 */
export default function ChoixRDV(
    {jwt, idUser, services, sites}:
        {
            jwt: string,
            idUser: number,
            services: Service[],
            sites: InterventionSite[]
        }
): React.ReactElement {

    const [idService, setIdService] = React.useState<number>(undefined);
    const [duration, setDuration] = React.useState<number>(undefined);


    /**
     * @description Refreshes the component's visual display by retrieving the intervention sites it can choose from
     */


    React.useEffect(() => {

        function siteChoice(event: any) {
            if (event.detail) {
                launchEvent({
                    idService: idService,
                    idSite: event.detail,
                    duration: duration,
                    typeRdv: "Test"
                });
            } else {
                setIdService(undefined);
            }

        }

        document.addEventListener("onSiteChoice", siteChoice);

        return () => {
            document.removeEventListener("onSiteChoice", siteChoice);
        }
    });



    /**
     * @description Launches an event indicating the user's choice of intervention site
     * @param data object containing information about the selected intervention site
     */
    function launchEvent(data: AppointmentChoice) {
        const choixRdvEffectuer: CustomEvent<AppointmentChoice> = new CustomEvent("onChoixEffectuer", {detail: data});
        document.dispatchEvent(choixRdvEffectuer);
    }


    return (
        <Tab.Container id="list-group-tabs-example">
            {idService ?
                <InterventionSiteChoice sites={sites}></InterventionSiteChoice>
                :
                <Row className="mb-6" style={{justifyContent: "center"}}>
                    <Col sm={6}>
                        <h2 style={{textAlign: "center"}}>Choix de la prestation désirée</h2>
                        <ListGroup>
                            {services.map((service) => (
                                <ListGroup.Item key={service.idService} className="listeChoixRdv">
                                    <div>
                                        <p className="titleService">{service.name}</p>
                                        <p className="descService">{service.description}</p>
                                    </div>
                                    <div className="badgeGrid">
                                        <Badge className="customBadge" bg="secondary">{service.durationString}</Badge>
                                        <Badge className="customBadge" bg="primary">{`${service.price}€`}</Badge>
                                        <Button variant="dark" onClick={() => {
                                            setIdService(service.idService)
                                            setDuration(service.duration)
                                        }}>Choisir</Button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            }
        </Tab.Container>
    )
}
