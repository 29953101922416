import * as React from 'react';
import {MDBBtn, MDBCheckbox, MDBContainer, MDBInput, MDBTextArea} from "mdb-react-ui-kit";
import {Form} from "react-router-dom";
import {CustomFormProps} from "../../types/type";
import {FormCheck, FormLabel, FormSelect} from "react-bootstrap";
export default function CustomForm(
    {inputs, titleButtonSend}: CustomFormProps
): React.ReactElement {

    function submit(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.target.reportValidity();
        }
    }

    return (
        <Form method="post" action="" onSubmit={submit}>
            <MDBContainer className="p-1 my-4 d-flex flex-column w-75">

                {inputs.map((input) => {
                    if (input.type === "textarea") return <MDBTextArea required={input.required !== undefined ? input.required : true} name={input.name} key={input.name} wrapperClass='mb-4' value={input.value} onChange={(e) => input.setValue({[input.name]: e.target.value})} label={input.label}></MDBTextArea>
                    else if (input.type === "hidden") return <input required={input.required !== undefined ? input.required : true} key={input.name} readOnly={true} hidden={true} value={input.value} name={input.name}></input>
                    else if (input.type === "checkbox") return <FormCheck required={input.required !== undefined ? input.required : true} type="switch" className="mb-4" label={input.label} name={input.name} key={input.name} value={input.value} defaultChecked={input.value} onChange={(e) => input.setValue({[input.name]: e.target.checked})}></FormCheck>
                    else if (input.type === "select") {
                        return (
                            <>
                                <FormLabel>{input.label}</FormLabel>
                                <FormSelect required={input.required !== undefined ? input.required : true} key={input.name} className="mb-4" name={input.name} value={input.value} onChange={(e) => input.setValue({[input.name]: {id: e.target.value, lastName: ""}})}>
                                    <option value="undefined">Rien</option>
                                    {input.selectItems.map(item => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </FormSelect>
                            </>
                        )
                    }
                    else return <MDBInput required={input.required !== undefined ? input.required : true} pattern={input.pattern ? input.pattern : undefined} step="any" min={0} key={input.name} wrapperClass='mb-4' label={input.label} value={input.value} onChange={(e) => input.setValue({[input.name]: e.target.value})} type={input.type} name={input.name}></MDBInput>
                })}

                <MDBBtn className="mb-4" type="submit">{titleButtonSend}</MDBBtn>

            </MDBContainer>
        </Form>
    )
}
